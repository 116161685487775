export const apiUrls = {
  dashboardAPIUrl: '/v1/fetch/user/partnership/dashboard/details',
  getAllPartnershipAPI: '/v1/fetch/user/partnerships',
  fetchUserDetailsAPI: '/v1/fetch/user',
  fetchCompanyProfileAPI: '/v1/fetch/company/user',
  submitBankDetails: '/v1/submit/bank/detail',
  ifsc: '/v1/fetch/detail/ifsc/',
  logOut: '/v2/logout',
  sendUserOTP: '/otp/v2/send',
  sendUserOTPV3: '/otp/v3/send',
  validateOTP: '/otp/v2/validate',
  signUp: '/v4/signup',
  RsaKey: '/v1/fetch/publicKey',
  GraphConfig: '/v1/fetch/graphConfiguration',
  addPartnership: '/v1/add/user-partnership/fetch/tasks',
  GraphData: '/v1/fetch/graph/details',
  policyData: '/policy/document/v1/get/group',
  policyFetch: '/policy/document/v1/fetch',
  policySubmit: '/policy/document/v1/submit',
  fetchUserData: '/v1/fetch/user',
  saveUserProfile: '/v1/updateUserDetails',
  fetchInvoice: '/v2/list/invoice/',
  fetchReceipt: '/list/receipt/',
  fetchSelectedInvoice: '/v1/invoice/fetch',
  fetchSelectedReceipt: '/v1/receipt/fetch',
  reportingFilter: '/v2/fetch/reporting/details',
  updateUserProfile: '/user/v2/update/profile',
  getLicenseMandate: '/lcoUserService/user/isBlocked',
  addEmployee: 'api/v1/add/employee',
  aadhaarOtp: 'api/v1/aadhar/otp/send',
  faceRecognition: 'api/v1/employee/fetch/recognition',
  fetchTeamDetails: 'api/v1/employee/list',
  fetchTurboIvp: '/v1/generate/ivp/token',
  forgotPassword: '/user/password/v2/reset',
  resetPassword: '/user/password/v2/update',
  fetchRoles: 'api/v1/employee/roles',
  refreshToken: '/token/v2/refresh',
  submitContactDetails: '/lead/add',
  validateOtpV3: '/otp/v3/validate'
};

export const befeUrls = {
  landing: 'team/landingPage',
  login: 'team/loginPage'
};
