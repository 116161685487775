import { testIds } from '../../../constants/testId';
import styles from './Loader.module.scss';

const Loader = () => {
    return (
        <div className={styles.loaderFullScreen} data-testid={testIds['loader-component']}>
            <div className={styles["loader-big"]} />
        </div>
    );
  };
  
  export default Loader;