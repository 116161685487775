import { testIds } from '../../../constants/testId';
import Modal from '../../common/Modal/Modal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '../../atoms/Button/Button';
import './errorModal.scss';
import { clearNotification } from '../../../redux/actions/actions';
const NotificationModal = ({ notificationReducer, clearNotification }) => {
  let { type, message, messageDisplay, tertiaryMsg, btnText } = notificationReducer;
  return (
    <div className={`modal-container ${type}`} data-testid={testIds['notification-modal']}>
      {!!type && (
        <Modal variant="notification">
          <div>
            <div className={`${type == 'ERROR' ? 'error-notification' : 'success-notification'}`}>
              <div className="notification-wrapper">
                <div className="icon"></div>
                <div className="messageBold">{type != 'ERROR' && message ? message : ''}</div>
                <div className="messageBold">
                  {type == 'ERROR' && !messageDisplay ? 'Something Went Wrong !' : ''}
                </div>

                <div className="message">{messageDisplay}</div>
                {tertiaryMsg && (
                  <>
                    <div className="tertiaryMsg">{tertiaryMsg}</div>
                    <div className="blueHover" onClick={clearNotification}>
                      {btnText}{' '}
                    </div>
                  </>
                )}
                {!tertiaryMsg && (
                  <div data-testid={testIds['notification-modal-close']} className="button">
                    <Button variant="primary" btnText="OK" onClick={clearNotification}></Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  notificationReducer: state.notificationReducer
});

const mapDispatchToProps = (dispatch) => ({
  clearNotification: () => dispatch(clearNotification())
});

NotificationModal.propTypes = {
  notificationReducer: PropTypes.object,
  clearNotification: PropTypes.func
};
export default connect(mapStateToProps, mapDispatchToProps)(NotificationModal);
