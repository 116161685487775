import { testIds } from '../../../constants/testId';
import styles from './Modal.module.scss'
import PropTypes from 'prop-types';
import { useState } from 'react';

const Modal = ({ header, children, handleClose, variant }) => {
    const [show, setShow] = useState(true);
    return (
        <>
            {show &&
                <>
                    <div className={`${styles.backdrop} ${styles[variant]}`} />
                    <div className={styles.modal} data-testid={testIds['modal-component']}>
                        {header && <div className={styles.header}>
                            <div className="head-msg">{header}</div>
                            <div className={styles.close} data-testid={testIds['close-modal']} onClick={() => { setShow(false); handleClose && handleClose(); }}>x</div>
                        </div>}
                        <div className={styles.body}>
                            {children}
                        </div>
                    </div>
                </>
            }
        </>
    );
};

Modal.propTypes = {
    header: PropTypes.string,
    children: PropTypes.object,
    handleClose: PropTypes.func,
    variant: PropTypes.string
}

export default Modal;