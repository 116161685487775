import { apiUrls } from '../../constants/apiUrls';
import { handlePromise } from '../helpers';

export const getDashboardData = async (params) => {
  const apiPayload = {
    method: 'GET',
    url: apiUrls.dashboardAPIUrl,
    params: params
  };

  return handlePromise(apiPayload);
};
