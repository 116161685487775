import {
   events,
   getGlobalDimensions,
   initLib,
   debouncedFireEvent,
   fireEvent,
} from './functions';
import { TrackEventTypes } from './interfaces';

/**
 * event method with promise callback
 * @param {object} data
 */
export const trackEvent = (data: TrackEventTypes, immediate = false) => {
   if (!data) return;
   const trackingData = getGlobalDimensions(data);
   for (const key in data) if (data[key]) trackingData[key] = data[key];

   trackingData['ctaTs'] = new Date().getTime();
   events.push(trackingData);

   if (immediate) {
      fireEvent();
   } else {
      debouncedFireEvent();
   }
};

/**
 * init method to set default values for SDK
 *
 * @param {object} config contains
 ** {string} appName - to set global dimensions
 ** {boolean} allowClickEventListener
 ** {number} delay
 ** {number} batchSize
 *
 * appName to be send by frontend
 * so that global dimensions can be differentiated for different pages
 */
export const init = initLib;

// exposing methods to global for CDN
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (BUILD_TYPE == 'cdn') window.Clickstream = { init, trackEvent };
