import { apiUrls, befeUrls } from '../../constants/apiUrls';
import { handlePromise } from '../apiControllers/helpers';
import ENV from '../../environments';
import { getUserLoggedInDetails } from '../../components/common/Utility/Utility';

export const getLoginPageConfig = () => {
  const apiPayload = {
    method: 'GET',
    url: ENV.getBefeBasePath() + befeUrls.login
  };

  return handlePromise(apiPayload);
};

export const sendUserOTP = (payload) => {
  const apiPayload = {
    method: 'POST',
    url: ENV.getTeamIAMUrl() + apiUrls.sendUserOTP,
    data: payload
  };

  return handlePromise(apiPayload);
};

export const sendUserOTPV3 = (payload) => {
  const apiPayload = {
    method: 'POST',
    url: ENV.getTeamIAMUrl() + apiUrls.sendUserOTPV3,
    data: payload
  };

  return handlePromise(apiPayload);
};

export const validateOtp = (payload) => {
  const apiPayload = {
    method: 'POST',
    url: ENV.getTeamIAMUrl() + apiUrls.validateOTP,
    data: payload
  };

  return handlePromise(apiPayload);
};

export const SignUp = (payload, headers) => {
  const apiPayload = {
    method: 'POST',
    url: ENV.getTeamIAMUrl() + apiUrls.signUp,
    data: payload,
    headers: headers
  };

  return handlePromise(apiPayload);
};

export const fetchPublicRsaKeyAPI = () => {
  const apiPayload = {
    method: 'GET',
    url: ENV.getTeamCommonUrl() + apiUrls.RsaKey,
    publicKey: true
  };

  return handlePromise(apiPayload);
};

export const userLogin = (headers, payload, isPartner) => {
  const apiPayload = {
    method: 'POST',
    url: ENV.getTeamIAMUrl() + `/${isPartner ? 'v5' : 'v6'}/login?`,
    data: payload,
    headers: headers
  };

  return handlePromise(apiPayload);
};

export const forgotPassword = (payload) => {
  const apiPayload = {
    method: 'POST',
    url: ENV.getTeamIAMUrl() + apiUrls.forgotPassword,
    data: payload
  };

  return handlePromise(apiPayload);
};

export const resetPassword = (payload) => {
  const apiPayload = {
    method: 'POST',
    url: ENV.getTeamIAMUrl() + apiUrls.resetPassword,
    data: payload
  };

  return handlePromise(apiPayload);
};

export const refreshUserSession = () => {
  const { refreshToken } = getUserLoggedInDetails();
  let headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
  };

  let body = 'refreshToken=' + encodeURIComponent(refreshToken);
  const apiPayload = {
    method: 'POST',
    url: ENV.getTeamIAMUrl() + apiUrls.refreshToken,
    data: body,
    headers
  };

  return handlePromise(apiPayload);
};

export const validateOtpV3 = (payload) => {
  const apiPayload = {
    method: 'POST',
    url: ENV.getTeamIAMUrl() + apiUrls.validateOtpV3,
    data: payload
  };

  return handlePromise(apiPayload);
};
