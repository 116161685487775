export const msmed = [
  { value: 'MEDIUM_SCALE', displayName: 'Medium Scale' },
  { value: 'SMALL_SCALE', displayName: 'Small Scale' },
  { value: 'MICRO_SCALE', displayName: 'Micro Scale' }
];

export const booleanOptions = [
  { value: true, displayName: 'Yes' },
  { value: false, displayName: 'No' }
];

export const infoMsg =
  'Partners having annual turnover exceeding INR 50 crore in FY 2017-18 and onwards, are covered under e-invoicing w.e.f 1st April 2021. Post which, no invoice would be accepted by us if issued without valid QR code.If you select answer to be "Yes", It will mean that IRN/E-Invoicing will be applicable on the invoices submitted by you. As a result, You would be required to print IRN and QR code on all invoices raised against Airtel group companies.';

export const onboardingOptions = [
  { displayName: 'GST', key: 'GST' },
  { displayName: 'PAN', key: 'PAN' }
];
