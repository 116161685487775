import React, { useState, useEffect } from "react";
import { testIds } from "../../../constants/testId";
import './textArea.scss'
/* eslint-disable react/prop-types */

const defaultMaxChars = 300;
const TextArea = (props) => {
    const maxCharCount = props.maxCharCount || defaultMaxChars;
    const { input, meta, placeHolder } = props;
    const [val, setVal] = useState(input.value || "");
    const [count, setCount] = useState(maxCharCount - val.length);

    const handleChange = (event) => {
        const { value } = event && event.target;
        setVal(value);
        setCount(maxCharCount - value.length);
    };

    /* Update max char length on prepopulation */
    useEffect(() => {
        setVal(input.value || "");
        setCount(maxCharCount - val.length);
    }, [input.value, val]);

    return (

        <div className="textAreaWrapper">
            <fieldset className="height-auto">
                <div className="co-name no-margin">
                    <label>{props.label}</label>
                    <textarea data-testid={testIds['text-area-component']}
                        className="text-area-sm"
                        placeholder={placeHolder}
                        rows="2"
                        cols="50"
                        autoComplete="off"
                        role="textbox"
                        aria-autocomplete="list"
                        aria-haspopup="true"
                        name={props.name}
                        value={val}
                        maxLength={maxCharCount}
                        {...input}
                        onChange={(ev) => {
                            input.onChange(ev);
                            handleChange(ev);
                        }}
                    />
                    <span className="minimum">Characters left: {count}</span>
                </div>
                {meta && meta.error && meta.touched && (
                    <span className="input-error-msg textareaerror">{meta.error}</span>
                )}
            </fieldset>

        </div>
    );
};
export default TextArea;
