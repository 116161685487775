import { infoMsg } from './msmed.config';
import React from 'react';
import Button from '../../atoms/Button/Button';
import './confirmation.scss';
import PropTypes from 'prop-types';
import Modal from '../../common/Modal/Modal';
import { testIds } from '../../../constants/testId';
const Confirmation = ({ goToNextStep, handleClose }) => {
  return (
    <Modal header={`Information`} handleClose={handleClose}>
      <div className="confirm-modal-body" data-testid={testIds['confirmation-modal']}>
        <p>{infoMsg}</p>
        <div className="center-button">
          <Button
            data-testid={testIds['next-step-button']}
            onClick={() => {
              goToNextStep();
              handleClose();
            }}
            variant="primary"
            className="btn"
            btnText="Confirm"
          />
        </div>
      </div>
    </Modal>
  );
};

Confirmation.propTypes = {
  setModal: PropTypes.func,
  goToNextStep: PropTypes.func,
  handleClose: PropTypes.handleClose
};
export default Confirmation;
