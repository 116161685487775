export const KEY_MAPPINGS = {
   appVersion: 'av',
   browserHeight: 'bh',
   browserName: 'browserName',
   browserVersion: 'browserVersion',
   browserWidth: 'bw',
   campaignId: 'camId',
   channel: 'channel',
   colorDepth: 'cd',
   cookieSupport: 'cs',
   correlationId: 'cid',
   createdTime: 'createdTime',
   ctaTs: 'ct',
   date: 'date',
   deviceId: 'p41',
   deviceName: 'dn',
   domContentLoadTs: 'dt',
   eventDate: 'eventDate',
   eventTs: 'eventTs',
   firstTouchChannel: 'firstTouchChannel',
   gaid: 'gaid',
   hitNumber: 'hitNumber',
   id: 'id',
   internalCampaignId: 'iCamId',
   internal_campaign: 'uic',
   internal_medium: 'uim',
   internal_source: 'uis',
   ipAddress: 'ipAddress',
   isUserLoggedIn: 'isuli',
   loadTimeInMilliseconds: 'loadTimeInMilliseconds',
   navigationstartTs: 'nt',
   networkSpeed: 'ns',
   os: 'e5',
   osName: 'os',
   osVersion: 'osv',
   pageLoadTs: 'pt',
   platform: 'platform',
   referrer: 'r',
   sessionId: 'sid',
   timezone: 'tz',
   ucid: 'ucid',
   url: 'url',
   userType: 'ut',
   utm_campaign: 'uc',
   utm_medium: 'um',
   utm_source: 'us',
   visitNumber: 'visitNumber',
   visitorId: 'vid',
   appNumber: 'appNumber',
   broadbandCount: 'broadbandCount',
   circle: 'e25',
   city: 'city',
   customLabel: 'customLabel',
   customValue: 'customValue',
   customerTier: 'e27',
   dthCount: 'dthCount',
   eventAction: 'eventAction',
   eventCategory: 'eventCategory',
   eventLabel: 'eventLabel',
   eventValue: 'eventValue',
   homesId: 'homesId',
   horizontalPosition: 'horizontalPosition',
   isInteractive: 'isInteractive',
   isMember: 'e49',
   isOneAirtel: 'isOneAirtel',
   journey: 'e26',
   language: 'p15',
   latitude: 'latitude',
   longitude: 'longitude',
   msisdn: 'e20',
   newApp: 'newApp',
   newInstrumentation: 'newInstrumentation',
   oapbbCount: 'oapbbCount',
   oapdthCount: 'oapdthCount',
   oappostCount: 'oappostCount',
   postpaidCount: 'postpaidCount',
   prepaidCount: 'prepaidCount',
   previousPageName: 'ppn',
   primaryLob: 'p23',
   primaryNumberType: 'p20',
   secondaryLob: 'p22',
   secondaryMsisdn: 'e21',
   secondaryNumberType: 'p21',
   section: 'section',
   status: 'status',
   statusIndicator: 'statusIndicator',
   subSection: 'subSection',
   timeSpent: 'timeSpent',
   verticalPosition: 'verticalPosition',
   abVariable: 'p10',
   accountId: 'p24',
   addressSelectionType: 'p34',
   airtelWeekend: 'p32',
   arp: 'p27',
   basePackId: 'e38',
   benefitAmount: 'benefitAmount',
   bundleId: 'p16',
   cardType: 'e31',
   cartId: 'cartId',
   category: 'p45',
   childMsisdn: 'p30',
   childSimType: 'p47',
   cityAutoPick: 'e34',
   cityChanged: 'p42',
   cityResolutionType: 'e4',
   citySource: 'e35',
   cmsPageId: 'p40',
   connectionType: 'e40',
   contentGenre: 'e28',
   contentLanguage: 'e19',
   contentPackageId: 'e48',
   contentPercentage: 'e16',
   contentSource: 'e6',
   couponCode: 'p25',
   currentPageName: 'cpn',
   customCampaignId: 'e3',
   customerCohort: 'customerCohort',
   customerSegmentId: 'e42',
   devicePrice: 'devicePrice',
   deviceType: 'p6',
   discount: 'discount',
   email: 'email',
   error: 'e32',
   extraData10: 'ed10',
   extraData11: 'ed11',
   extraData12: 'ed12',
   extraData13: 'ed13',
   extraData14: 'ed14',
   extraData15: 'ed15',
   extraData16: 'ed16',
   extraData17: 'ed17',
   extraData18: 'ed18',
   extraData19: 'ed19',
   extraData1: 'ed1',
   extraData20: 'ed20',
   extraData2: 'ed2',
   extraData3: 'ed3',
   extraData4: 'ed4',
   extraData5: 'ed5',
   extraData6: 'ed6',
   extraData7: 'ed7',
   extraData8: 'ed8',
   extraData9: 'ed9',
   feasibilityBucket: 'e10',
   feedData1: 'fd1',
   feedData2: 'fd2',
   feedData3: 'fd3',
   feedData4: 'fd4',
   feedData5: 'fd5',
   feedUserType: 'p8',
   freeInvitesLeft: 'freeInvitesLeft',
   gender: 'p38',
   googleClickId: 'e11',
   googleClickKeyword: 'e18',
   homepageCitySelection: 'p44',
   isHighRise: 'p33',
   leadAddress: 'p13',
   leadEmail: 'p14',
   leadExtraData: 'p26',
   leadId: 'e8',
   leadName: 'p12',
   leadRtn: 'e36',
   map: 'e7',
   metaData: 'p35',
   noOfChildren: 'noOfChildren',
   notificationMessageId: 'e14',
   notificationStatus: 'e13',
   numberOfFloors: 'e39',
   offerId: 'e30',
   offerSource: 'e29',
   oneAirtelsLob: 'p9',
   pageVersion: 'p31',
   partnerId: 'p19',
   paymentFlow: 'paymentFlow',
   paymentStack: 'p3',
   paymentType: 'e24',
   pincode: 'e33',
   planName: 'p5',
   planRental: 'p4',
   postingId: 'postingId',
   primaryAddedAccounts: 'e45',
   productId: 'productId',
   prop0: 'p0',
   prop10: 'p10',
   publicWifiAttach: 'e12',
   savedCards: 'e44',
   secondaryAddedAccounts: 'e46',
   serviceId: 'e15',
   slotDate: 'p0',
   slotTime: 'e41',
   speechIcon: 'e47',
   subCategory: 'p46',
   targetPlan: 'e43',
   thanksBenefitDetails: 'p11',
   thirdPartyPartnerId: 'p18',
   ticketId: 'ticketId',
   totalDuration: 'totalDuration',
   transactionAmount: 'transactionAmount',
   transactionId: 'e23',
   transactionMode: 'transactionMode',
   transactionSource: 'transactionSource',
   upgrade: 'p36',
   userHierarchy: 'e9',
   vasCode: 'e37',
   walletAmount: 'walletAmount',
};
