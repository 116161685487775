import { useState } from 'react';
import { testIds } from '../../../constants/testId';
import { toKB } from '../../common/Utility/commonUtils';
import styles from './FileSelect.module.scss';
import PropTypes from 'prop-types';

const FileSelect = ({ onChange, accept, showError }) => {
  const [file, setFile] = useState(null);

  const handleImage = (event) => {
    const { files } = event && event.target;
    if (
      files &&
      files[0].name &&
      !accept
        .split(',')
        .includes('.' + files[0].name.split('.')[files[0].name.split('.').length - 1].toLowerCase())
    ) {
      showError({ message: `File extension should be one of ${accept}` });
      return;
    }
    setFile({ fileName: files[0].name, fileSize: toKB(files[0].size) });
    onChange({ data: files[0] });
  };
  return (
    <fieldset className={styles['upload']} data-testid={testIds['file-input-wrapper']}>
      <label className="pan-proof">Upload PAN for verification</label>
      {!file && (
        <div className={styles['uploadWrapper']}>
          <input
            data-testid={testIds['file-input']}
            type="file"
            id="file"
            name="uploadPan"
            accept={accept}
            className={styles['file']}
            onChange={handleImage}
            //  ref={uploadInp}
          />
          <label className={styles['fileInstruction']}>Upload From Device</label>
        </div>
      )}
      {file && file.fileName && (
        <>
          <div className={styles['uploadWrapper']}>
            <span className="upload-proof-name">{file.fileName}</span>
            <span className={styles['size']}>{file.fileSize}</span>
            <i
              className={styles['iconCross']}
              onClick={() => {
                setFile(null);
                onChange(null);
              }}
            >
              x
            </i>
          </div>
        </>
      )}
      <p className={styles['fileStatus']}>
        (Please upload a JPG/JPEG/PNG, file size not exceeding 2MB)
      </p>
    </fieldset>
  );
};

FileSelect.propTypes = {
  onChange: PropTypes.func,
  accept: PropTypes.string,
  showError: PropTypes.func
};

export default FileSelect;
