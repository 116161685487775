import { useState } from 'react';
import { getPanDetails, submitPanDetails } from '../../../api/apiControllers/team.onboarding';
import FileSelect from '../../atoms/FileSelect';
import InputGrp from '../../atoms/InputGrp';
import styles from './information.module.scss';
import { panValidatorWithError, validatePincode } from '../../common/Utility/commonUtils';
import Button from '../../atoms/Button/Button';
import { useNavigate } from 'react-router-dom';
import { proceedToNextTask } from '../CompanyProfile/fetchCompanyData';
import { notificationSuccess, notificationFail } from '../../../redux/actions/actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getEncryptedMessage } from '../../common/Utility/Utility';
import { testIds } from '../../../constants/testId';
const OnboardingThroughPan = ({ notificationFail, notificationSuccess }) => {
  const [pan, setPan] = useState('');
  const [error, setError] = useState({});
  let navigate = useNavigate();
  const [panDetails, setPanDetails] = useState(null);
  const [file, setFile] = useState(null);
  const [address, setAddress] = useState(null);
  const [pinCode, setPinCode] = useState('');

  const submitPanDetailsForm = async () => {
    try {
      let bodyParams = new FormData();
      bodyParams.append('file', file && file.data);
      bodyParams.append('tncAccepted', 'true');
      bodyParams.append('address', address);
      bodyParams.append('name', panDetails.registeredName);
      bodyParams.append('tradeName', panDetails.registeredName);
      bodyParams.append('pinCode', pinCode);
      bodyParams.append('pan', getEncryptedMessage(pan));
      bodyParams.append('taskId', 'ADD_COMPANY_DETAILS_PAN_GST');
      await submitPanDetails(bodyParams);
      proceedToNextTask(navigate);
      notificationSuccess('Data Updated Successfully!!');
    } catch (e) {
      console.log(e);
    }
  };
  const changeHandler = async (e) => {
    if (e.target.name == 'pan') {
      setPan(e.target.value);
      setPanDetails(null);
      let panError = panValidatorWithError(e.target.value);
      let err = { ...error, [e.target.name]: panError };
      setError(err);
      if (panError) {
        return;
      }
      let data = await getPanDetails(e.target.value);
      setPanDetails(data);
      console.log(data);
    }
    if (e.target.name == 'pincode') {
      setPinCode(e.target.value);
      let pincodeError = validatePincode(e.target.value);
      let err = { ...error, [e.target.name]: pincodeError };
      setError(err);
    }
    if (e.target.name == 'address') {
      setAddress(e.target.value);
    }
  };

  const checkDisabled = () => {
    return error.pan || error.pincode || !pinCode || !file || !address;
  };

  return (
    <>
      <InputGrp
        onChange={changeHandler}
        label={'Enter Pan'}
        type="text"
        minLength={10}
        autoComplete="off"
        maxLength={10}
        name="pan"
        error={error.pan}
        className="input"
        required="required"
      />
      {panDetails && (
        <>
          <div className={`${styles['detailRow']} ${styles['panDetails']}`}>
            <div className={styles['heading']}>Name:</div>
            <div className={styles['data']}> {panDetails.registeredName}</div>
          </div>
          <InputGrp
            onChange={changeHandler}
            label={'Enter Address'}
            type="text"
            autoComplete="off"
            maxLength={300}
            name="address"
            error={error.address}
            className="input"
            required="required"
          />
          <InputGrp
            onChange={changeHandler}
            label={'Enter Pincode'}
            type="text"
            minLength={6}
            autoComplete="off"
            maxLength={6}
            name="pincode"
            error={error.pincode}
            className="input"
            required="required"
          />
          <FileSelect
            accept={'.png,.jpg,.jpeg'}
            onChange={(data) => {
              setFile(data);
            }}
            showError={(error) => {
              notificationFail(error);
            }}
          />
          <div data-testid={testIds['onboard-pan-submit']}>
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                submitPanDetailsForm();
              }}
              btnText="Save and Continue"
              disabled={checkDisabled()}
            />
          </div>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (msg) => dispatch(notificationSuccess(msg)),
  notificationFail: (msg) => dispatch(notificationFail(msg))
});

OnboardingThroughPan.propTypes = {
  notificationSuccess: PropTypes.func,
  notificationFail: PropTypes.func
};

export default connect(null, mapDispatchToProps)(OnboardingThroughPan);
