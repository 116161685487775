import {
  getUserLoggedInDetails,
  removeAllDataFromStorage
} from '../../components/common/Utility/Utility';
import { apiUrls } from '../../constants/apiUrls';
import { handlePromise } from '../apiControllers/helpers';
import ENV from '../../environments';
import { PATHS } from '../../constants/paths';
import { createBrowserHistory } from 'history';

const doLogout = async () => {
  const history = createBrowserHistory();
  try {
    const { refreshToken, accessToken, tokenType, userUuid } = getUserLoggedInDetails();
    let headers = {
      refresh_token: refreshToken,
      Authorization: `${tokenType} ${accessToken}`,
      'USER-ID': userUuid
    };
    const apiPayload = {
      method: 'POST',
      url: ENV.getTeamIAMUrl() + apiUrls.logOut,
      isLogout: true,
      headers: headers
    };
    await handlePromise(apiPayload);
    removeAllDataFromStorage();
  } catch (e) {
    console.log(e);
  } finally {
    removeAllDataFromStorage();
    history.push(ENV.getBaseName() + PATHS.login);
    window.location.reload();
  }
};

export default doLogout;
