import { InputLabel, Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl } from '@material-ui/core';
import PropTypes from 'prop-types';
import './select.scss';
import { testIds } from '../../../constants/testId';
import Paragraph from '../Paragraph/Paragraph';

const SingleSelect = ({ options, onChange, label, input, meta, value, variant }) => {
  return (
    <div className={`selectGrp  ${variant}`}>
      <FormControl variant="standard">
        {label && <InputLabel className="">{label}</InputLabel>}
        <Select
          data-testid={testIds['select-component']}
          onChange={(e) => {
            input && input.onChange(e);
            onChange && onChange(e);
          }}
          {...input}
          disabled={!options}
          value={input && input.value ? input.value : value + ''}>
          {!options && (
            <MenuItem value="">
              <em>No options</em>
            </MenuItem>
          )}
          {options &&
            options.map((option) => (
              <MenuItem
                data-testid={testIds['menu-item-component']}
                key={option.key}
                value={option.key}>
                <Paragraph
                  text={option.displayName}
                  variant="paragraph"
                  className="select-pragraph-tag"
                />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {meta && meta.error && meta.touched && <span className="input-error-msg">{meta.error}</span>}
    </div>
  );
};
SingleSelect.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  label: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  value: PropTypes.string,
  variant: PropTypes.string,
  placeholder: PropTypes.string
};
export default SingleSelect;
