import { apiUrls } from '../../constants/apiUrls';
import { handlePromise } from '../helpers';

export const getAllPartnershipData = async () => {
  const apiPayload = {
    method: 'GET',
    url: apiUrls.getAllPartnershipAPI
  };

  return handlePromise(apiPayload);
};
