import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PATHS } from './constants/paths';
import { isAuthenticated } from './components/common/Utility/Utility';
import { useEffect, useState } from 'react';
import LicenseMandate from './components/organisms/LicenseMandate';
import { checkLicenseMandate } from './api/CheckLicenseMandate';

const AuthProvider = ({ children }) => {
  const isAuth = isAuthenticated();
  const location = useLocation();
  const [mandate, setMandate] = useState(null);
  useEffect(() => {
    if (isAuth) {
      checkLicenseMandate().then((resp) => {
        setMandate(resp);
      });
    }
  }, [location]);

  if (mandate && mandate.blocked) {
    return <LicenseMandate data={mandate} />;
  }

  return <>{isAuth ? children : <Navigate to={PATHS.login} replace />}</>;
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default AuthProvider;
