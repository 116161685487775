//import { testIds } from "../../../constants/testId";
//import Header from '../../molecules/Header/Header';
import styles from './companyProfile.module.scss';
import Tabs from '../../molecules/Tabs/tabs';
import tabConfig from './tab.config.js'
import { testIds } from '../../../constants/testId';
import { getDashboardData } from '../../../api/DashboardAPI';
import { useEffect, useState } from 'react';
import { getCurrentTaskConfig } from './taskHelpers';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchCompanyData } from './fetchCompanyData';
import { useNavigate } from 'react-router-dom';
import { getFromSessionStorage } from '../../common/Utility/Utility';

const CompanyProfile = () => {
    const navigate = useNavigate();
    const [tasks, setTasks] = useState(null);
    const [tabsConfigProcessed, setTabsConfigProcessed] = useState(null);
    const fetchTasksData = async () => {
        try {
            let selectedPartnership = JSON.parse(getFromSessionStorage("selectedPartnership"));
            if (!selectedPartnership || !selectedPartnership.id) {
                navigate(-1);
            }
            const { userPartnershipTaskResponse } = await getDashboardData({ "partnership-id": selectedPartnership.id });
            if (userPartnershipTaskResponse && userPartnershipTaskResponse.tasks) {
                setTasks(userPartnershipTaskResponse.tasks);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchTasksData();
        fetchCompanyData();
    }, [])

    useEffect(() => {
        let tasksConfiguration = getCurrentTaskConfig(tasks, [...tabConfig]);
        setTabsConfigProcessed(tasksConfiguration);
    }, [tasks])

    return (
        <div className={styles.container} data-testid={testIds["company-profile"]}>
            <div className={styles["headInfo"]} >
                <p>COMPANY PROFILE</p>
            </div>
            {tabsConfigProcessed && tabsConfigProcessed.length > 0 && <Tabs info={tabsConfigProcessed} />}
        </div>
    );
};


const mapStateToProps = (state) => ({
    companyProfileState: state.companyProfileState,
    selectedPartnership: state.selectedPartnershipReducer
});

CompanyProfile.propTypes = {
    companyProfileState: PropTypes.object,
    selectedPartnership: PropTypes.object
}
export default connect(mapStateToProps, null)(CompanyProfile);
