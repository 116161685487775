import { ACTIONS } from '../../constants/constants';

const loaderReducer = function (state = false, action) {
  switch (action.type) {
    case ACTIONS.SHOW_LOADER:
      return action.data;
    case ACTIONS.HIDE_LOADER:
      return action.data;
    default:
      return state;
  }
};

export default loaderReducer;
