//import { testIds } from "../../../constants/testId";
//import Header from '../../molecules/Header/Header';
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Tabs.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { testIds } from '../../../constants/testId';
const Tabs = ({ info }) => {
    const location = useLocation();
    const navigate = useNavigate()
    const search = location.search;
    const name = new URLSearchParams(search).get('tab');

    const switchTab = (tab) => {
        if (tab.disable) {
            return;
        }
        navigate(`${location.pathname}?tab=${tab.name}`);
    }

    useEffect(() => {
        let tabFound = null;
        info && info.map((tab) => {
            if (tab.name == name) {
                tabFound = true;
            }
        })
        if (!tabFound) {
            if (info.length > 0) {
                navigate(`${location.pathname}?tab=${info[0].name}`);
            }
        }
    }, [name])

    return (
        <>
            <div className={styles["tabs"]} data-testid={testIds["tabs-component"]}>
                {info && info.map((tab, index) => {
                    let activeClass = `${tab.name == name ? "active" : ""}`;
                    return (
                        <div key={index} className={`${styles["tab"]} ${styles[activeClass]}`} data-testid={`tab-element-component-${index}`} onClick={() => { switchTab(tab, index) }}>
                            {tab.heading}
                        </div>
                    )
                })}
            </div>
            <div className={styles["selectedTab"]} data-testid={`tab-selected`}>
                {info && info.map((tab, index) => {
                    if (tab.name == name) {
                        const { Component } = tab;
                        return (<div key={index} className={styles["selectedContent"]}>
                            <Component />
                        </div>)
                    }
                })}
            </div>
        </>
    );
}

Tabs.proptypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            tab: PropTypes.shape({ heading: PropTypes.string, name: PropTypes.string, Component: PropTypes.element })
        })
    )
}
Tabs.defaultProps = {
    info: []
}
export default Tabs;