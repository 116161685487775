import { apiUrls } from '../../constants/apiUrls';
import { handlePromise } from './helpers';
import ENV from '../../environments';
import { getEncryptedMessage } from '../../components/common/Utility/Utility';

export const fetchByIfsc = async (ifsc) => {
  const apiPayload = {
    method: 'GET',
    url: ENV.getPennyDropURL() + `${apiUrls.ifsc}${ifsc}`
  };
  return handlePromise(apiPayload);
};

export const submitBankDetails = (body) => {
  const apiPayload = {
    method: 'POST',
    url: apiUrls.submitBankDetails,
    data: body
  };
  return handlePromise(apiPayload);
};

export const submitBusinessDetails = (body) => {
  const apiPayload = {
    method: 'POST',
    url: `/company/v1/type`,
    data: body
  };
  return handlePromise(apiPayload);
};

export const getCategoryBusiness = (headers) => {
  const apiPayload = {
    method: 'get',
    headers: headers,
    url: `/company/v1/types`
  };
  return handlePromise(apiPayload);
};

export const getGstdetails = (gst) => {
  const apiPayload = {
    method: 'get',
    url: `/v1/gst/details/${gst}`
  };
  return handlePromise(apiPayload);
};

export const getPanDetails = (pan) => {
  const apiPayload = {
    method: 'get',
    url: `/v1/pan/details?panNumber=${encodeURIComponent(getEncryptedMessage(pan))}`
  };

  return handlePromise(apiPayload);
};

export const submitGstDetails = (formData) => {
  const apiPayload = {
    method: 'post',
    url: `/v1/submit/company`,
    data: formData
  };
  return handlePromise(apiPayload);
};

export const submitPanDetails = (formData) => {
  const apiPayload = {
    method: 'post',
    url: `/v1/submit/company/pan`,
    data: formData
  };
  return handlePromise(apiPayload);
};

export const getStarted = () => {
  const apiPayload = {
    method: 'get',
    url: `/v1/fetch/user/partnerships?status=NOT_STARTED`
  };
  return handlePromise(apiPayload);
};

export const pennyDropStatus = () => {
  const apiPayload = {
    method: 'get',
    url: '/api/v1/account/validation/status'
  };
  return handlePromise(apiPayload);
};
