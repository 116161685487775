import { useState } from "react";
import PropTypes from 'prop-types';
import { testIds } from "../../../../constants/testId";
import showPassWordImg from "../../../../assets/images/svg/eyeVisible.svg"
import dontShowPassWordImg from "../../../../assets/images/svg/eyeInVisible.svg"
const PasswordField = (props) => {
  const { input, meta, name, label, noInstruction, isDisable, forgotpwdLbl, forgotpwd, variant } = props;

  const [containMinChars, setMinChars] = useState(false);
  const [containNumber, setNumberCheck] = useState(false);
  const [containUpperCase, setUpperCaseCheck] = useState(false);
  const [containSpclCase, setSpclCaseCheck] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const goodPassword = () => {
    return containMinChars && containNumber && containUpperCase && containSpclCase;
  }
  const handleChange = (ev) => {
    const value = ev.target && ev.target.value;
    //Minimum 6 characters
    setMinChars(value.length >= 6 ? true : false);

    //Minimum one number
    setNumberCheck(value.length > 0 && /\d/.test(value) ? true : false);

    //Minimum one upper case
    setUpperCaseCheck(value.length > 0 && /[A-Z]/.test(value) ? true : false);

    //Minimum one special characters
    const format = /[!@`~#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]+/;
    setSpclCaseCheck(value.length > 0 && format.test(value) ? true : false);
  };

  return (
    <fieldset>
      <div className={`input-container ${variant}`}>
        <label>{label}</label>
        <input
          name={name}
          value={props.value}
          {...input}
          onChange={(ev) => {
            handleChange(ev);
            input.onChange(ev);
            props.onChange && props.onChange(ev);
          }}
          autoComplete={props.autoComplete}
          className="input"
          required={true}
          disabled={isDisable}
          data-testid="input-password"
          type={showPass ? "text" : "password"}
        />
        {forgotpwd && <span className="fg-pass" data-testid={testIds["forgot-pwd"]} onClick={forgotpwd}>
          {forgotpwdLbl || "Forgot Password?"}
        </span>}
        <div className="bar" />
        <div className="showPassWord" onClick={() => { setShowPass(!showPass); }} >
          <img src={showPass ? showPassWordImg : dontShowPassWordImg} />
        </div>
      </div>

      {meta.error && meta.touched && (
        <span className="input-error-msg password-tick">{meta.error}</span>
      )}

      {!noInstruction && !goodPassword() && !!input.value && <div className="pass-req">
        <span className={`${containMinChars ? "active" : ""}`}>

          Minimum 6 characters
        </span>
        <span className={`${containNumber ? "active" : ""}`}>

          one numeric character is must
        </span>
        <span className={`${containUpperCase ? "active" : ""}`}>
          one upper-case character is must
        </span>
        <span className={`${containSpclCase ? "active" : ""}`}>
          one special character is must
        </span>
      </div>}
    </fieldset>
  );
};

PasswordField.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  }),
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string
  }),
  onChange: PropTypes.func,
  autoComplete: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  noInstruction: PropTypes.bool,
  isDisable: PropTypes.bool,
  forgotpwd: PropTypes.func,
  forgotpwdLbl: PropTypes.string,
  variant: PropTypes.string
};

export default PasswordField;
