import { lazy } from 'react';

export const DashboardWrapperComponentLazy = lazy(() =>
  import('./components/organisms/DashboardWrapper/DashboardWrapper')
);

export const GetStartedComponentLazy = lazy(() =>
  import('./components/molecules/GetStartedScreen/GetStartedScreen')
);

export const SelectPartnershipComponentLazy = lazy(() =>
  import('./components/organisms/SelectPartnership/SelectPartnership')
);

export const DashboardComponentLazy = lazy(() =>
  import('./components/organisms/Dashboard/Dashboard')
);

export const SignupComponentLazy = lazy(() => import('./components/organisms/Signup/Signup'));

export const PolicyComponentLazy = lazy(() => import('./components/organisms/Policy/Policy'));

export const ReceiptsComponentLazy = lazy(() => import('./components/organisms/Receipts/Receipts'));

export const InvoicesComponentLazy = lazy(() => import('./components/organisms/Invoices/Invoices'));

export const ReportingComponentLazy = lazy(() =>
  import('./components/organisms/Reporting/Reporting')
);

export const ForgotPassword = lazy(() => import('./components/organisms/ForgotPassword'));

export const UpdatePassword = lazy(() =>
  import('./components/organisms/ForgotPassword/UpdatePassword')
);

export const LandingPage = lazy(() => import('./components/organisms/LandingPage/'));
export const Login = lazy(() => import('./components/organisms/LoginPage'));
