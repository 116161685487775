import { apiUrls } from '../../constants/apiUrls';
import env from '../../environments';
import { handlePromise } from '../helpers';

export const checkLicenseMandate = async (params) => {
  const apiPayload = {
    method: 'GET',
    url: env.getLcoBasePath() + apiUrls.getLicenseMandate,
    params: params
  };

  return handlePromise(apiPayload);
};
