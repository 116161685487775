import PropTypes from 'prop-types';
import { testIds } from '../../../constants/testId';
import styles from './Button.module.scss';

const ButtonComponent = (props) => {
  const { variant, btnText, type, disabled, onClick, className } = props;
  const disabledClass = disabled && disabled ? 'disabled' : '';

  return (
    <button
      className={`${styles[variant]} ${styles[disabledClass]} ${className}`}
      type={type ? type : 'button'}
      data-testid={testIds['button-component']}
      onClick={onClick}
      disabled={disabled}>
      {btnText}
    </button>
  );
};

ButtonComponent.propTypes = {
  variant: PropTypes.string,
  btnText: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default ButtonComponent;
