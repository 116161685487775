import axios from 'axios';
import { notificationFail, loadingAction } from '../redux/actions/actions';
import store from '../redux/store';
import {
  getFromSessionStorage,
  getUserLoggedInDetails,
  isLogOutCall,
  isOnBoardingSeviceUrl,
  isRefreshCall,
  isVendor,
  refreshSession
} from '../components/common/Utility/Utility';
import ENV from '../environments';
import doLogout from './LogoutAPI';

const Axios = axios.create({
  baseURL: ENV.getBaseUrl(),
  timeout: 30000
});

export default Axios;
const { dispatch } = store;

Axios.interceptors.request.use((config) => {
  let selectedPartnership = JSON.parse(getFromSessionStorage('selectedPartnership'));
  //add loader
  dispatch(loadingAction(true));
  const loggedInUser = getUserLoggedInDetails();
  const accessToken = loggedInUser && loggedInUser.accessToken;
  const tokenType = loggedInUser && loggedInUser.tokenType;
  if (tokenType === undefined && accessToken && !isRefreshCall(config)) {
    config.headers['Authorization'] = accessToken;
  } else if (tokenType && accessToken && !isRefreshCall(config)) {
    config.headers['Authorization'] = `${tokenType} ${accessToken}`;
  }
  if (loggedInUser && loggedInUser.userUuid) {
    config.headers['USER-ID'] = loggedInUser.userUuid;
  }
  config.headers['APP-ID'] = (isVendor() && 'INSURANCE_VENDOR') || 'TEAM';
  if (loggedInUser && loggedInUser.UUID && !config.publicKey) {
    config.headers['APP-ID'] = loggedInUser.appId;
  }
  if (loggedInUser && loggedInUser.UUID && !config.publicKey && !config.isLogout) {
    config.headers['UUID'] = loggedInUser.UUID;
  }
  if (isOnBoardingSeviceUrl(config) &&  selectedPartnership?.id) {
    config.headers['partnership-id'] =  selectedPartnership.id;
  }
  if (!config?.url?.includes('v6/login')) {
    config.headers['teams-platform-secure'] = 'TEAM';
  }

  return config;
});

const UNAUTHORIZED = 401;
Axios.interceptors.response.use(
  // success handler
  (response) => {
    //remove loader
    dispatch(loadingAction(false));
    return response.data;
  },
  // error handler
  async (error) => {
    const { status } = error.response || {};
    const { displayMessage } = (error.response && error.response.data) || {};
    const originalRequest = error.config;
    // Remove loader
    dispatch(loadingAction(false));
    if (status !== UNAUTHORIZED) {
      dispatch(notificationFail(error, displayMessage));
    }
    if (
      error.response.status === UNAUTHORIZED &&
      !originalRequest._retry &&
      !isLogOutCall(originalRequest) &&
      !isRefreshCall(originalRequest)
    ) {
      originalRequest._retry = true;
      await refreshSession();
      return Axios(originalRequest);
    }
    if (status === UNAUTHORIZED && isRefreshCall(originalRequest)) {
      //DoLogout
      dispatch(notificationFail(error, displayMessage));
      await doLogout();
    }

    return Promise.reject(error);
  }
);
