import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../common/Modal/Modal';
import './LicenseMandate.scss'
import { testIds } from '../../../constants/testId';
import ButtonComponent from '../../atoms/Button/Button';
import signOut from '../../../assets/images/png/sign-outicon.png'
import signOutFilled from '../../../assets/images/svg/SignoutFilled.svg'
import doLogout from '../../../api/LogoutAPI';
const LicenseMandate = ({ data }) => {
    let logoutClass = (data && data.displayContent && data.displayContent.indexOf("contract-approver-template")) ? "logoutBottom" : "logout";
    let btnVariant = logoutClass === "logoutBottom" ? "primary-white-upper" : "primary-3";
    let src = (data && data.displayContent && data.displayContent.indexOf("contract-approver-template")) ? signOutFilled : signOut;
    return (
        <Modal variant="mandate">
            <div className={"licenseBodyWrapper"}>
                <div className={logoutClass}><ButtonComponent variant={btnVariant} btnText="Logout" onClick={() => { doLogout() }} /> <img src={src} className={"logoutImg"} /></div>
                <div data-testid={testIds["mandate-html"]} dangerouslySetInnerHTML={{ __html: data && data.displayContent }} />
            </div>
        </Modal>
    );
};

LicenseMandate.propTypes = {
    handleClose: PropTypes.handleClose,
    data: PropTypes.object
};
export default LicenseMandate;
