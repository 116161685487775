export const ANALYTIC_CONSTANT = 'aa-';
export const CS_VID = 'cs_vid';
export const CS_SID = 'cs_sid';
export const CS_CID = 'cs_cid';
export const UTM_SOURCE = 'utm_source';
export const UTM_MEDIUM = 'utm_medium';
export const UTM_CAMPAIGN = 'utm_campaign';
export const NEW = 'new';
export const REPEAT = 'repeat';
export const ICID = 'icid';
export const CID = 'cid';
export const AA_PI = 'aa-pi';
export const CPN = 'cpn';
export const PPN = 'ppn';
export const EMPTY_STRING = '';
export const CS_VISIT_NUMBER = 'cs_vn';
export const CS_VISIT_NUMBER_VID = 'cs_vn_vid';
export const CS_HIT_NUMBER = 'cs_hn';
export const CS_HIT_NUMBER_SID = 'cs_hn_sid';
export const ANALYTICS_BASE_URL_PROD = 'https://digianalytics.airtel.in/analytics';
export const ANALYTICS_BASE_URL_KONGQA = 'https://kongqa.airtel.com/app/analytics';
export const DOM_LOADED_DELAY = 300;
