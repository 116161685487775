import { setProfileDetails } from '../../../redux/actions/actions';
import { fetchCompanyProfile } from '../../../api/FetchCompanyProfile/index';
import store from '../../../redux/store';
import { getDashboardData } from '../../../api/DashboardAPI';
import { tasksStatus } from '../../molecules/TaskList/tasks.config';
import { getFromSessionStorage } from '../../common/Utility/Utility';
export const fetchCompanyData = async () => {
  const { dispatch } = store;
  try {
    const resp = await fetchCompanyProfile();
    dispatch(setProfileDetails(resp));
  } catch (error) {
    console.log(error);
  }
};

export const proceedToNextTask = async (navigate) => {
  try {
    let selectedPartnership = JSON.parse(getFromSessionStorage("selectedPartnership"));
    if (!selectedPartnership || !selectedPartnership.id) {
      navigate(-1);
    }
    const { userPartnershipTaskResponse } = await getDashboardData({ "partnership-id": selectedPartnership.id });
    if (userPartnershipTaskResponse && userPartnershipTaskResponse.tasks) {
      for (var i = 0; i < userPartnershipTaskResponse.tasks.length; i++) {
        if (userPartnershipTaskResponse.tasks[i].status == tasksStatus.NOT_STARTED) {
          if (navigate) {
            navigate(userPartnershipTaskResponse.tasks[i].url);
            navigate(0);
          }
          break;
        }
      }
      if (navigate) {
        navigate(0);
      }
    }
  } catch (error) {
    console.log(error);
  }
};
