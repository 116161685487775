import PropTypes from "prop-types";
import { connect } from "react-redux";
import { testIds } from "../../../constants/testId";

const ReadBankDetails = ({ companyProfileState }) => {
    let { bankDetails } = companyProfileState;
    return (
        <div data-testid={testIds["read-bank-details-component"]}>
            <div className="setting-row">
                <span>Account No. :
                </span>
                <span> {bankDetails && bankDetails.accountNumber}
                </span>
            </div>
            <div className="setting-row">
                <span>Name :
                </span>
                <span>{bankDetails && bankDetails.beneficiaryName}
                </span>
            </div>
            <div className="setting-row">
                <span>Bank Name: </span>
                <span>{bankDetails && bankDetails.bankName}
                </span>
            </div>
            <div className="setting-row">
                <span>IFSC: </span>
                <span>{bankDetails && bankDetails.ifscCode}
                </span>
            </div>

        </div>
    )

}

ReadBankDetails.propTypes = {
    companyProfileState: PropTypes.object
}
const mapStateToProps = (state) => ({
    companyProfileState: state.companyProfileState
});

export default connect(mapStateToProps, null)(ReadBankDetails);