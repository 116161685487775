import { put, takeLatest, all } from 'redux-saga/effects';
import { ACTIONS } from '../../constants/constants';

function* showNotification(props) {
  let { data } = props;
  let notification = {
    type: data.type || 'Error',
    code: data.code || 500,
    message: data.info || 'Something Went Wrong',
    messageDisplay: data.infoMessage || '',
    tertiaryMsg: data.tertiaryMsg,
    btnText: data.btnText
  };
  yield put({ type: ACTIONS.SET_NOTIFICATION, data: notification });
}

function* actionWatcher() {
  yield takeLatest(ACTIONS.GET_NOTIFICATION, showNotification);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
