import CryptoJS from 'crypto-js';
import config from '../../organisms/Login/keycloakConfig';
import Fingerprint from 'fingerprintjs';
import forge from 'node-forge';
import { fetchPublicRsaKeyAPI, refreshUserSession } from '../../../api/SignupAPI';
import jwt_decode from 'jwt-decode';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
import jwtDecode from 'jwt-decode';
import ENV from '../../../environments';

const airtelEDKey = 'A!rt3lOnl!n33ncrypt30n5tr!ngHJS3cur!ty';

export const notEmptyArray = (obj) => {
  return obj instanceof Array && obj.length !== 0;
};

export const removeFromLocalStorage = (moduleId, key) => {
  const localStorageKey = `${moduleId + key}`;
  if (localStorage[localStorageKey]) {
    delete localStorage[localStorageKey];
  }
};

export const isLocalStorageSupported = () => {
  const testKey = 'test';
  const storage = window.localStorage;
  try {
    storage.setItem(testKey, '1');
    storage.removeItem(testKey);
    return true;
  } catch (error) {
    return false;
  }
};

export const getDecryptedString = (ciphertext) => {
  if (!ciphertext) return ciphertext;
  var keyHex = CryptoJS.enc.Utf8.parse(airtelEDKey);
  // direct decrypt ciphertext
  var decrypted = CryptoJS.DES.decrypt(
    {
      ciphertext: CryptoJS.enc.Base64.parse(ciphertext)
    },
    keyHex,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }
  );
  var message = decrypted.toString(CryptoJS.enc.Utf8);

  //if encryption source was object
  try {
    message = JSON.parse(message);
    return message;
  } catch (err) {
    //if encryption source was string
    return message;
  }
};

export const getFromLocalStorage = (moduleId, key) => {
  const localStorageKey = `${moduleId + key}`;
  if (!localStorage[localStorageKey]) {
    return;
  }

  if (isLocalStorageSupported()) {
    return Object.assign({}, getDecryptedString(JSON.parse(localStorage[localStorageKey])));
  }
};

export const getEncryptedString = (message) => {
  if (!message) return message;
  if (typeof message === 'object') {
    message = JSON.stringify(message);
  } else {
    message = String(message);
  }
  var keyHex = CryptoJS.enc.Utf8.parse(airtelEDKey);
  var encrypted = CryptoJS.DES.encrypt(message, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
};

export const setInLocalStorage = (moduleId, key, obj) => {
  const localStorageKey = `${moduleId + key}`;
  if (isLocalStorageSupported()) {
    let cipherText = getEncryptedString(obj);
    cipherText = `"${cipherText}"`;
    localStorage[localStorageKey] = cipherText;
  }
};

export const getUserLoggedInDetails = () => {
  const loggedInDetails = getFromLocalStorage('team', 'login');
  return loggedInDetails;
};

export const getVendorLoggedInDetails = () => {
  const loggedInDetails = getFromLocalStorage('vendor', 'login');
  return loggedInDetails;
};

export const getDecodedTokenData = () => {
  try {
    const loggedInDetails = jwt_decode(getFromLocalStorage('team', 'login').accessToken);
    return loggedInDetails;
  } catch (e) {
    console.log(e, 'error is');
  }
};

export const setInSessionStorage = (key, val) => {
  sessionStorage.setItem(key, val);
};

export const getFromSessionStorage = (key) => {
  return sessionStorage.getItem(key);
};

export const removeFromSessionStorage = (key) => {
  return sessionStorage.removeItem(key);
};

export const loadTimeInterval = (initialTime) => {
  const loadDate = new Date().getTime();
  const loadInterval = loadDate - initialTime;
  return loadInterval || '';
};

export const getFormBody = () => {
  const { refreshToken, accessToken } = getUserLoggedInDetails();
  let details = {
    client_id: config.clientId,
    Authorization: accessToken,
    refresh_token: refreshToken
  };
  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + '=' + encodedValue);
  }
  formBody = formBody.join('&');

  return formBody;
};

export const removeAllDataFromStorage = () => {
  removeFromLocalStorage('team', 'login');
  removeFromLocalStorage('team', 'header');
  removeFromLocalStorage('company', 'details');
  removeFromSessionStorage('selectedPartnership');
  removeFromLocalStorage('lco', 'ToolTip');
  removeFromLocalStorage('vendor', 'login');
  removeFromLocalStorage('lco', '_addendum_notify');
};

export const isAuthenticated = () => {
  const userAuthDetail = getUserLoggedInDetails();
  return userAuthDetail && userAuthDetail.accessToken;
};

export const getUniqueMachineId = () => {
  var fingerprint = new Fingerprint(
    { canvas: true },
    { screen_resolution: true },
    { ie_activex: true }
  ).get();
  return fingerprint.toString();
};

export const getEncryptedRsaString = (message, publicRsaKey) => {
  if (!message || !publicRsaKey) return message;

  var pki = forge.pki;
  var pkey = publicRsaKey;
  var pkeyDer = forge.util.decode64(pkey);

  var pkeyAsn1 = forge.asn1.fromDer(pkeyDer);
  var publicKey = pki.publicKeyFromAsn1(pkeyAsn1);
  // eslint-disable-next-line no-undef
  var encryptedMsg = publicKey.encrypt(message, 'RSA-OAEP', {
    md: forge.md.sha256.create(),
    mgf1: {
      md: forge.md.sha1.create()
    }
  });
  return forge.util.encode64(encryptedMsg);
};

export const getEncryptionDetails = () => {
  const encryptionDetails = getFromLocalStorage('team', 'secure');
  return encryptionDetails;
};

export const getEncryptedMessage = (message) => {
  const keyDetails = getEncryptionDetails();
  const pubKey = keyDetails && keyDetails.publicKey;
  const encryptedMsg = getEncryptedRsaString(message, pubKey);
  return encryptedMsg;
};

export const getFirstAndLastName = (username) => {
  let firstName, lastName;
  username = username.trim();
  let nameSplitArray = username.split(' ').filter((el) => el);

  if (notEmptyArray(nameSplitArray)) {
    firstName = nameSplitArray[0];
    nameSplitArray.splice(0, 1);
    lastName = nameSplitArray.join(' ');
  }

  return { firstName, lastName };
};

/**
 * This function fetches public rsa key, used in encryption of data and store it in local storage
 */
export const fetchPublicRsaKey = async () => {
  const resp = await fetchPublicRsaKeyAPI();
  const pkey = resp && resp.key;
  const encryptionData = { publicKey: pkey };
  setInLocalStorage('team', 'secure', encryptionData);
};

/**
 * fetch public key only when it's not present in local storage
 */
export const fetchEncryptionData = async () => {
  const encyptionDetails = getEncryptionDetails();
  if (!encyptionDetails) {
    await fetchPublicRsaKey();
  }
};

/**
 * return if url is http url
 */
export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

/**
 *
 * @param {*} time
 * takes the date and convert into the format specified
 */
export const formatDate = (time, format) => {
  const moment = extendMoment(originalMoment);
  return moment(time).format(format || 'DD-MM-YYYY');
};

/**
 * This function returns the value of any numerical figure in Indian System
 */

export const getIndianCurrencySystem = (amount) => {
  return `₹${amount.toLocaleString('en-IN')}`;
};

/**
 * This function returns any type of date in string format to local date string format
 */

export const getLocalDate = (date) => {
  return new Date(date).toLocaleDateString();
};

/**
 * This function downloads the resources
 */

export const downloadResource = (fileData, fileName) => {
  const link = document.createElement('a');

  const url = window.URL.createObjectURL(new Blob([fileData]));
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  return true;
};

export const convertDecimalToReadableText = (number, decPlaces) => {
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);
  var abbrev = ['k', 'm', 'b', 't'];
  for (var i = abbrev.length - 1; i >= 0; i--) {
    var size = Math.pow(10, (i + 1) * 3);

    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number == 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      number += abbrev[i];
      break;
    }
  }

  return number;
};

export const getMaxMinForGraph = (data, config) => {
  let minIncomeAmount = 0;
  let maxIncomeAmount = 0;
  let minInvestmentAmount = 0;
  let maxInvestmentAmount = 0;
  data.map((val) => {
    if (parseFloat(val.incomeAmount) > maxIncomeAmount) {
      maxIncomeAmount = parseInt(val.incomeAmount);
    }
    if (parseFloat(val.incomeAmount) < minIncomeAmount) {
      minIncomeAmount = parseInt(val.incomeAmount);
    }
    if (parseFloat(val.investmentAmount) > maxInvestmentAmount) {
      maxInvestmentAmount = parseInt(val.investmentAmount);
    }
    if (parseFloat(val.investmentAmount) < minInvestmentAmount) {
      minInvestmentAmount = parseInt(val.investmentAmount);
    }
  });
  const maxIncome = Math.round(maxIncomeAmount / 1000) * 1000;
  const MaxIncomeRange =
    (maxIncome && maxIncome.toString().split('').length && ++maxIncome.toString().split('')[0]) *
    (maxIncome && Math.pow(10, maxIncome.toString().length - 1));

  const maxInvestment = Math.round(maxInvestmentAmount / 1000) * 1000;
  const MaxInvestmentRange =
    (maxInvestment &&
      maxInvestment.toString().split('').length &&
      ++maxInvestment.toString().split('')[0]) *
    (maxInvestment && Math.pow(10, maxInvestment.toString().length - 1));

  return {
    incomeAmount: {
      min: Math.round(minIncomeAmount / 1000) * 1000,
      max:
        config && config.type === 'breakeven'
          ? Math.max(MaxInvestmentRange, MaxIncomeRange)
          : MaxIncomeRange
    },
    investmentAmount: {
      min: Math.round(minInvestmentAmount / 1000) * 1000,
      max:
        config && config.type === 'breakeven'
          ? Math.max(MaxInvestmentRange, MaxIncomeRange)
          : MaxInvestmentRange
    }
  };
};

export const isLogOutCall = (request) => {
  const isLogOut = request.url.search('/v2/logout') !== -1;
  return isLogOut;
};
export const isRefreshCall = (request) => {
  const isRefresh = request.url.search('token/v2/refresh') !== -1;
  return isRefresh;
};

export const isOnBoardingSeviceUrl = (request) => {
  const isOnboardCall = request.url.search(ENV.getBaseUrl()) !== -1 || request.url.startsWith('/');
  return isOnboardCall;
};

export const refreshSession = async () => {
  try {
    const authorisation = await refreshUserSession();
    const { userUuid } = getUserLoggedInDetails();
    const accessToken = authorisation && authorisation.accessToken;
    let sessionMode = undefined;
    if (accessToken) {
      const accessTokenDecoded = jwtDecode(authorisation.accessToken);
      sessionMode = accessTokenDecoded && accessTokenDecoded.sessionMode;
    }
    setInLocalStorage('team', 'login', {
      ...authorisation,
      sessionMode,
      userUuid
    });
  } catch (e) {
    removeAllDataFromStorage();
    console.log(e);
  }
};

export const isVendor = () => {
  if (window.location.pathname.includes('vendor')) return true;
};
