import Information from '../Information/information';
import BankDetails from '../BankDetails/bankdetails';
import ReadBankDetails from '../BankDetails/readBankDetails';
import Business from '../BusinessDetails/businessDetails';
import ReadInformation from '../Information/readInformation';
import CompanyDetailsPan from '../Information/CompanyDetailsPan';
export default [
  {
    id: 'ADD_COMPANY_DETAILS',
    heading: 'Information',
    name: 'gst',
    Read: ReadInformation,
    Write: Information,
    Component: ReadInformation
  },
  {
    id: 'ADD_COMPANY_DETAILS_PAN_GST',
    heading: 'Information',
    name: 'pan_gst',
    Read: ReadInformation,
    Write: CompanyDetailsPan,
    Component: ReadInformation
  },
  {
    id: 'ADD_BANK_DETAILS',
    heading: 'Bank details',
    name: 'bank',
    Read: ReadBankDetails,
    Write: BankDetails,
    Component: ReadBankDetails
  },
  {
    id: 'ADD_BUSINESS_DETAILS',
    heading: 'Business details',
    name: 'personalized',
    Read: Business,
    Write: Business,
    Component: Business
  }
];
