import Heading from '../../atoms/Heading/Heading';
import styles from './userProfile.module.scss';
import { Field, Form } from 'react-final-form';
import {
  composeValidators,
  mustBeA10DigNumber,
  mustBeAlphabetes,
  mustBeStrongPassword,
  requiredFieldValidator
} from '../../common/Utility/validator';
import Button from '../../atoms/Button/Button';
import { testIds } from '../../../constants/testId';
import { notificationSuccess } from '../../../redux/actions/actions';
import Input from '../../atoms/Input/input';
import { fetchUserDetails } from '../../../api/FetchUserDetails';
import { useState, useEffect } from 'react';
import { getEncryptedMessage, getFirstAndLastName, getFromSessionStorage } from '../../common/Utility/Utility';
import { saveUserProfile } from '../../../api/SaveUserProfileAPI';
import PasswordField from '../../organisms/Signup/Components/PasswordField';
import validationMessage from '../../common/Utility/validationMessage';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../constants/paths';

const UserProfile = ({ notificationSuccess }) => {
  const [userProfileData, setUserProfile] = useState({});
  const [changePassword, showChangePass] = useState(false);
  let navigate = useNavigate();
  const fetchUserProfile = async () => {
    try {
      const resp = await fetchUserDetails();
      setUserProfile(resp && resp.user);
    } catch (e) {
      console.log(e);
    }
  };

  const ChangePasswordFields = () => {
    return (
      <div data-testid={testIds['change-pass-fields']} className="ease-open">
        <Field
          required={true}
          component={Input}
          label="Enter Old Password"
          type="password"
          name="oldPassword"
          key="oldPassword"
          validate={composeValidators([requiredFieldValidator])}
        />
        <Field
          required={true}
          component={PasswordField}
          label="Enter New Password"
          type="password"
          name="newPassword"
          key="newPassword"
          validate={composeValidators([requiredFieldValidator, mustBeStrongPassword])}
        />
        <Field
          required={true}
          component={Input}
          label="Re-Enter New Password"
          type="password"
          name="confirmPassword"
          key="confirmPassword"
          validate={composeValidators([requiredFieldValidator])}
        />
      </div>
    );
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);
  const SubmitProfileDetails = async (values) => {
    try {
      const username = values['name'];
      const { firstName, lastName } = getFirstAndLastName(username);
      let { id } = JSON.parse(getFromSessionStorage('selectedPartnership'));
      let body = {
        firstName: firstName,
        lastName: lastName,
        mobileNumber: values['mobile'],
        newPassword: getEncryptedMessage(values['newPassword']),
        oldPassword: getEncryptedMessage(values['oldPassword'])
      };
      await saveUserProfile(body);
      notificationSuccess('Data Updated Successfully!!');
      navigate(PATHS.dashboard + `?id=${id}`);
    } catch (e) {
      console.log(e);
    }
  };

  const getInitialValues = () => {
    if (userProfileData) {
      let { firstName, mobile, email, lastName } = userProfileData;
      return {
        name: firstName + (lastName ? (" " + lastName) : ""),
        mobile: mobile,
        email: email,
        password: '..........',
        role: 'Admin' //will come from token later
      };
    }
  };

  return (
    <div className={styles['userProfile']} data-testId={testIds['user-profile-page']}>
      <Heading text="My Profile" variant="h2-red" />
      {userProfileData && (
        <Form
          onSubmit={(values) => SubmitProfileDetails(values)}
          initialValues={getInitialValues()}
          /**
           * Validation based on other form fields are here ..
           */
          validate={(values) => {
            const errors = {};
            if (values.newPassword !== values.confirmPassword) {
              errors.confirmPassword = validationMessage.PASSWORD_MATCH_MSG;
            }
            return errors;
          }}
          render={({ handleSubmit, form, hasValidationErrors }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div className={styles['formSection']}>
                <div className={styles['fields']}>
                  <Field
                    required={true}
                    label="Full Name"
                    component={Input}
                    name="name"
                    key="name"
                    validate={composeValidators([requiredFieldValidator, mustBeAlphabetes])}
                  />
                  <Field
                    required={true}
                    label="Contact Number"
                    component={Input}
                    name="mobile"
                    key="mobile"
                    validate={composeValidators([requiredFieldValidator, mustBeA10DigNumber("Contact Number")])}
                  />
                  <Field
                    required={true}
                    label="Email Id"
                    component={Input}
                    name="email"
                    key="email"
                    isDisable={true}
                  />
                  <Field
                    required={true}
                    label="Role"
                    component={Input}
                    name="role"
                    key="role"
                    isDisable={true}
                  />
                  {/**Change password dummy field . It is used to open the form and hides itself */}
                  {!changePassword && (
                    <Field
                      required={true}
                      component={PasswordField}
                      isDisable={true}
                      label="Password"
                      type="password"
                      name="password"
                      key="password"
                      noInstruction={true}
                      forgotpwd={() => {
                        showChangePass(true);
                      }}
                      validate={composeValidators([requiredFieldValidator])}
                      forgotpwdLbl="Change Password"
                    />
                  )}
                  {changePassword && <ChangePasswordFields />}
                </div>
              </div>
              <div className={styles['actions']}>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    showChangePass(false);
                    form.reset();
                  }}
                  className="button-inline"
                  data-testid={testIds['user-details-reset-button']}>
                  <Button variant="primary-white" btnText="Cancel" />
                </div>
                <Button
                  variant="primary"
                  disabled={hasValidationErrors}
                  type="submit"
                  btnText="Update"
                />
              </div>
            </form>
          )}
        />
      )}
    </div>
  );
};

UserProfile.propTypes = {
  notificationSuccess: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (msg) => dispatch(notificationSuccess(msg)),
});

export default connect(null, mapDispatchToProps)(UserProfile);
