import { ACTIONS } from '../../constants/constants';

export const loadingAction = (bool) => {
  return bool
    ? {
        type: 'SHOW_LOADER',
        data: bool
      }
    : {
        type: 'HIDE_LOADER',
        data: bool
      };
};

export const setProfileDetails = (data) => {
  return { type: ACTIONS.SET_COMPANY_PROFILE, data: data };
};

export const notificationFail = (error, message) => {
  return {
    type: ACTIONS.GET_NOTIFICATION,
    data: { type: 'ERROR', info: error.message, infoMessage: message || error.message }
  };
};

export const notificationSuccess = (message) => {
  return { type: ACTIONS.GET_NOTIFICATION, data: { type: 'SUCCESS', code: 200, info: message } };
};

export const notificationSuccessNewVariant = (message, tertiaryMsg, btnText) => {
  return {
    type: ACTIONS.GET_NOTIFICATION,
    data: { type: 'SUCCESS_VARIANT_NEW', code: 200, info: message, tertiaryMsg, btnText }
  };
};

export const clearNotification = () => {
  return { type: ACTIONS.CLEAR_NOTIFICATION, data: null };
};

export const setSelectedPartnership = (data) => {
  return { type: ACTIONS.SET_SELECTED_PARTNERSHIP, data };
};

export const setLandingPagePartnership = (data) => {
  return { type: ACTIONS.SET_LANDING_PAGE_PARTNERSHIP, data };
};
