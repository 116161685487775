/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios from 'axios';
import { getDefaultParameters } from './params';
import { trackEvent } from './index';
import { fireLogWithSentry } from './sentry';
import { KEY_MAPPINGS } from './keyMappings';
import {
   ANALYTICS_BASE_URL_PROD,
   ANALYTICS_BASE_URL_KONGQA,
   ANALYTIC_CONSTANT,
   CS_CID,
   CS_SID,
   CS_VID,
} from './constants';
import {
   create_UUID,
   getSessionStorage,
   getUUID,
   setCookie,
   setSessionStorage,
} from './utils';
import { GLOBAL_DIMENSIONS } from './dimensions';
import { setDefaultValues } from './main';
import { InitParamTypes } from './interfaces/InitParamTypes';

// eslint-disable-next-line
export let events = [];
let sessionKey = '';
let delayTimer = 1000;
let _batchSize = 0;
let _apiEnv = 'PROD';
const baseUrl = {
   PROD: ANALYTICS_BASE_URL_PROD,
   KONGQA: ANALYTICS_BASE_URL_KONGQA,
};

/**
 * find "HTMLElement" those have analytic attributes
 * @param {HTMLElement} element
 * @returns {HTMLElement}
 */
const findAnalyticsAttribute = (element: HTMLElement) => {
   if (!element) return null;
   let attribute = null;
   try {
      attribute =
         element.getAttribute('data-analytics') ||
         (element.dataset && element.dataset.analytics);
      if (attribute) return attribute;
      else {
         const parent = element.parentElement;
         return findAnalyticsAttribute(parent);
      }
   } catch (error) {
      fireLogWithSentry(
         'functions.ts-->findAnalyticsAttribute--clickstream: error occurred in clickstream sdk:',
         error
      );
   }
   return attribute;
};

/**
 * if "HTMLElement" have data-analytics attribute
 * then it can listen for click listeners
 */
export const initClickEventListener = () => {
   try {
      document.addEventListener(
         'click',
         function (event: Event) {
            event = event || window.event;
            const target = event.target as HTMLElement;
            let attributes = findAnalyticsAttribute(target);
            if (!attributes) {
               return;
            } else {
               attributes = JSON.parse(attributes);
               trackEvent(attributes);
            }
         },
         false
      );
   } catch (error) {
      fireLogWithSentry(
         'functions.ts-->trackEvent-->clickstream: error occurred in initClickEventListener',
         error
      );
   }
};

/**
 * generate url with all the analytics data
 * @param {string} baseUrl
 * @param {object} params
 * @returns {string}
 */
const generateUrlWithParams = (baseUrl, params) => {
   try {
      let paramString = '?';
      let url = '';

      for (const key in params) {
         if (Object.hasOwnProperty.call(params, key)) {
            paramString += `${key}=${params[key]}&`;
         }
      }

      url = encodeURI(`${baseUrl}${paramString}`);
      return url;
   } catch (error) {
      fireLogWithSentry(
         'function.ts-->generateUrlWithParams-->clickstream: error while generating url with all the analytics data',
         error
      );
   }
};

export const getGlobalDimensions = (data) => {
   let globalDimensions = {};

   // sessionKey = appName send by frontend in init function
   // data.prop0 for backward compatibility
   sessionKey =
      sessionKey ||
      data.prop0 ||
      location.pathname.split('/')[1] ||
      location.origin.split('//')[1];

   try {
      // so that global dimensions can be differentiated for different pages
      globalDimensions =
         getSessionStorage(ANALYTIC_CONSTANT + sessionKey.toLowerCase()) || {};

      // merging session and app data
      for (const key in data) {
         // adding only global dimensions in globalData
         if (Object.prototype.hasOwnProperty.call(GLOBAL_DIMENSIONS, key)) {
            globalDimensions[key] = data[key];
         }
      }
      // also storing api timeout in sessionStorage so that it's value can persist
      // and frontend doesn't need to send it in every call
      if (data['timeout']) globalDimensions['timeout'] = data['timeout'];

      setSessionStorage(ANALYTIC_CONSTANT + sessionKey.toLowerCase(), globalDimensions);
   } catch (error) {
      fireLogWithSentry(
         'index.ts-->getGlobalDimensions-->clickstream: error while merging with global data',
         error
      );
   }
   return globalDimensions;
};

// adds global and custom dimensions to params
const getMappedParams = (params) => {
   // getting default dimensions (to be captured by sdk only )
   try {
      const urlParameters = getDefaultParameters(params);
      const mappedParams = {};

      // adding global and custom dimensions to urlParameters
      if (params != null) {
         for (const key in params) {
            // this also overrides any default dimension if passed by frontend
            if (key && Object.hasOwnProperty.call(KEY_MAPPINGS, key)) {
               urlParameters[key] = params[key];
            }
         }
         for (const key in urlParameters) {
            if (key && Object.hasOwnProperty.call(KEY_MAPPINGS, key)) {
               mappedParams[KEY_MAPPINGS[key]] = urlParameters[key];
            }
         }
      }
      return mappedParams;
   } catch (error) {
      fireLogWithSentry(
         'function.ts-->getMappedParams-->clickstream: error while adding global and custom dimensions to params',
         error
      );
   }
};

/**
 * callback handler to make network reqest to backend
 * @param {object} params
 */
const submitAnalytics = (params) => {
   try {
      const mappedParams = getMappedParams(params);
      const url = generateUrlWithParams(`${baseUrl[_apiEnv]}/pixel/V2`, mappedParams);
      // @ts-ignore
      if (BUILD_TYPE == 'cdn') {
         fetch(url, { method: 'GET' });
      } else {
         axios({ method: 'GET', url, timeout: params['timeout'] });
      }
      // fetch(url, { method: 'GET' });
   } catch (error) {
      fireLogWithSentry(
         'functions.ts-->submitAnalytics-->clickstream: error while submiting data',
         error
      );
   }
};

const submitAnalyticsBulk = (batchData) => {
   try {
      const stringifiedBatchData = batchData.map((event) =>
         JSON.stringify(getMappedParams(event))
      );
      const url = `${baseUrl[_apiEnv]}/bulk-pixel/V2`;
      // @ts-ignore
      if (BUILD_TYPE == 'cdn') {
         fetch(url, {
            method: 'POST',
            body: JSON.stringify(stringifiedBatchData),
            headers: {
               'Content-Type': 'application/json',
            },
         });
      } else {
         axios({
            method: 'POST',
            url,
            timeout: batchData[0]['timeout'],
            headers: {
               'Content-Type': 'application/json',
            },
            data: JSON.stringify(stringifiedBatchData),
         });
      }
   } catch (error) {
      fireLogWithSentry(
         'functions.ts-->submitAnalyticsBulk-->clickstream: error while submiting data',
         error
      );
   }
};

/**
 * init method to set default values for SDK
 *
 * @param {object} config contains
 ** {string} appName - to set global dimensions
 ** {boolean} allowClickEventListener
 ** {number} delay
 ** {number} batchSize
 ** {string} apiEnv - PROD/KONGQA
 *
 * appName to be send by frontend
 * so that global dimensions can be differentiated for different pages
 */
const defaultConfig = {
   appName: '',
   allowClickEventListener: false,
   delay: 1000,
   batchSize: 500,
   apiEnv: 'PROD',
};
export const initLib: (config: InitParamTypes) => void = (config = defaultConfig) => {
   const {
      appName = '',
      allowClickEventListener = false,
      delay = 1000,
      batchSize = 500,
      apiEnv = 'PROD',
   } = config as Record<string, any>;
   const corelationId = create_UUID();
   getUUID(CS_VID); // for setting default value
   getUUID(CS_SID); // for setting default value
   setCookie(CS_CID, corelationId);
   setDefaultValues(corelationId);
   sessionKey = appName;
   _batchSize = batchSize;
   delayTimer = delay;
   _apiEnv = apiEnv;
   if (allowClickEventListener) initClickEventListener();
};

/**
 * fireEvent once document is fully loaded
 */
export const fireEvent = () => {
   if (events.length) {
      if (events.length == 1) {
         submitAnalytics(events.splice(0, 1)[0]);
      } else {
         while (events.length) {
            const batchData = events.splice(0, _batchSize);
            submitAnalyticsBulk(batchData);
         }
      }
   }
};

/**
 * debounces callback function for given timeout
 * @param {Function} callback
 * @param {number} timeout
 * @param {boolean} immediate
 * @returns {Function}
 */
const debounce = (
   callback: (...params: unknown[]) => unknown,
   timeout = 20,
   immediate = false
) => {
   let timer: NodeJS.Timeout;

   return function (this: unknown, ...args: unknown[]) {
      if (timer === undefined && immediate) {
         callback.apply(this, args);
      }
      clearTimeout(timer);
      timer = setTimeout(() => callback.apply(this, args), timeout);
      return timer;
   };
};

export const debouncedFireEvent = () => {
   debounce(fireEvent, delayTimer)();
};
