import React, { useEffect, useState } from 'react';
import defaultbankLogo from '../../../../assets/images/png/bank-default-logo.png';
import airtelLogo from '../../../../assets/images/png/airtelLogo.png';
import PropTypes from 'prop-types';
import './bankDetailModal.scss';
import { testIds } from '../../../../constants/testId';
import { useNavigate } from 'react-router-dom';
import { pennyDropStatus } from '../../../../api/apiControllers/team.onboarding';
import { pennyDropStatusMap, validationTextMap } from '../../../../constants/constants';
import cross_icon from '../../../../assets/images/svg/cross.svg';

export const getVerificationStatus = (pennyDropResp) => {
  if (isStatusCompleted(pennyDropResp)) return 'Successful';
  else if (pennyDropResp.pennyDroppingStatus === pennyDropStatusMap.VERIFICATION_FAILED)
    return 'Failed';
  else return 'Pending';
};

export const isStatusCompleted = (pennyDropResp) => {
  return [pennyDropStatusMap.COMPLETED, pennyDropStatusMap.SUCCESS].includes(
    pennyDropResp.pennyDroppingStatus
  );
};

export const isStatusPending = (pennyDropResp) => {
  return [pennyDropStatusMap.INITIATED, pennyDropStatusMap.VERIFICATION_IN_PROGRESS].includes(
    pennyDropResp.pennyDroppingStatus
  );
};

let timer;
const BankDetailModal = ({ bankDetailResponse, onProcced, setShow }) => {
  const [pennyDropResp, setPennyDropResp] = useState(bankDetailResponse);
  let navigate = useNavigate();
  useEffect(() => {
    if (isStatusCompleted(pennyDropResp)) {
      clearInterval(timer);
    } else if (isStatusPending(pennyDropResp)) {
      clearInterval(timer);
      timer = setInterval(async () => {
        try {
          let statusResp = await pennyDropStatus();
          setPennyDropResp({ ...pennyDropResp, ...statusResp });
        } catch (e) {
          console.log(e);
          setPennyDropResp({ ...pennyDropResp, pennyDroppingStatus: 'VERIFICATION_FAILED' });
          clearInterval(timer);
        }
      }, 10000);
    } else {
      clearInterval(timer);
    }
  }, [pennyDropResp]);

  return (
    <>
      <div className="bank_detail_overlay" data-testid={testIds['penny-drop-modal']}></div>
      <div
        className={`paymentpopup ${
          isStatusCompleted(pennyDropResp) ? ' successfully-payment' : 'failed-payment'
        }`}
      >
        {pennyDropResp.pennyDroppingStatus === pennyDropStatusMap.VERIFICATION_FAILED && (
          <img
            onClick={() => {
              setShow(false);
            }}
            src={cross_icon}
            className="iconCross"
          />
        )}
        <div className="status-icon">
          <div className="status">&nbsp;</div>
        </div>
        <h4>{getVerificationStatus(pennyDropResp)}</h4>
        <>
          <h2 className="amount">
            <span>₹ 1</span>
          </h2>
          <p>{validationTextMap[pennyDropResp.pennyDroppingStatus]}</p>
        </>
        {bankDetailResponse.status != 'COMPLETED' && <p>{pennyDropResp.errorMessage}</p>}
        <div className="transfer-detail">
          <div className="from-transfer">
            <label>From</label>
            <img src={airtelLogo} />
          </div>
          <div className="to-transfer">
            <label>To</label>
            <div className="bank-detail">
              {pennyDropResp.accountHolderName && (
                <span>
                  Account Holder :<label>{pennyDropResp.accountHolderName}</label>
                </span>
              )}
              {pennyDropResp.bankName && (
                <span>
                  Bank : <img src={defaultbankLogo} className="bank-logo" />{' '}
                  <label>{pennyDropResp.bankName}</label>
                </span>
              )}
              {pennyDropResp.bankAccountNumber && (
                <span>
                  Account no. :<label>{pennyDropResp.bankAccountNumber} </label>
                </span>
              )}
            </div>
          </div>
        </div>
        {isStatusCompleted(pennyDropResp) && (
          <button
            type="submit"
            className="btn-procced"
            data-testid={testIds['onProceed']}
            onClick={() => onProcced(navigate)}
          >
            Proceed
          </button>
        )}
      </div>
    </>
  );
};
BankDetailModal.propTypes = {
  bankDetailResponse: PropTypes.object,
  onProcced: PropTypes.func,
  setShow: PropTypes.func
};
export default BankDetailModal;
