import PropTypes from 'prop-types';
import { testIds } from '../../../constants/testId';

const InputGrp = (props) => {
  return (
    <fieldset data-testid={testIds['input-group-wrapper']}>
      <div className="input-container">
        <input {...props} data-testid={testIds['input-grp']} />
        <label role="label">{props.label}</label>
      </div>
      <div role="error" className="input-error-msg">
        {props.error && props.error}
      </div>
    </fieldset>
  );
};

export default InputGrp;
InputGrp.propTypes = {
  label: PropTypes.string,
  error: PropTypes.object
};
