import { ACTIONS } from '../../constants/constants';

const selectedPartnership = function (state = {}, action) {
  switch (action.type) {
    case ACTIONS.SET_SELECTED_PARTNERSHIP:
      return action.data;
    default:
      return state;
  }
};

export default selectedPartnership;
