/* eslint-disable */
import validationMessage from './validationMessage';
import { notAnEmptyFile, getRangeInDays, notEmptyArray } from './commonUtils';
/**
 *
 * Contains all validations
 */

/**
 *
 * @param {*} multiLangRegex
 * returns the regex of JS
 */
const getRegex = (multiLangRegex) =>
  multiLangRegex.find((regexRule) => regexRule.lang === 'JS').regex;

export const requiredFieldValidator = (value) => {
  // if the value is empty array
  const isEmpty = value instanceof Array && value.length === 0;
  return value && !isEmpty ? undefined : validationMessage.REQUIRED;
};

export const minLengthValidator = (length, label) => (value) => {
  if (!value) return;
  return value.length <= length ? `${validationMessage.MIN_LENGTH_MSG}${label}` : undefined;
};

export const ifscValidator = (value) =>
  value.match(/^(?=^.{11}$)[A-Za-z0-9]+$/) ? undefined : validationMessage.INVALID_IFSC;

const matchRegexValidator = (regexStr, message) => (value) => {
  // This case has to be covered by required field validation
  if (!value) return;
  return new RegExp(regexStr).test(value) ? undefined : message;
};

export const emailValidator = (value) =>
  value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)
    ? undefined
    : validationMessage.INVALID_EMAIL;
export const mustBeANumber = (label) => (value) =>
  value.match(/^\d+$/) ? undefined : `${label}${' '}${validationMessage.MUST_BE_NUMBER}`;

export const mustBeA10DigNumber = (label) => (value) =>
  value.match(/^\d{10}$/) ? undefined : `${label}${' '}${validationMessage.MUST_BE_N_DIGITS}`;

export const mustbeA10DigAlphaNumericNum = (label) => (value) =>
  value.match(/^([a-zA-Z0-9]){10}$/)
    ? undefined
    : `${label}${' '}${validationMessage.MUST_BE_N_DIGITS_ALPHANUMERIC}`;

export const mustBeAlphabetes = (value) => {
  if (!value) return;
  return value.match(/^[a-zA-Z ]+$/) ? undefined : validationMessage.MUST_BE_ALPHABETES;
};

export const mustBeStrongPassword = (value) =>
  value.match(/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!@`~#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/)
    ? undefined
    : validationMessage.PASSWORD_REGEX_MSG;

const imageDimensionValidator = (resolution, message) => async (file) => {
  // in case if it is not the file i,e prepopulated with id only then no validation ..
  if (!notAnEmptyFile(file)) return;

  const { height, width } = resolution;
  let calcDimension = new Promise((resolve, reject) => {
    const image = new Image();
    image.src = URL.createObjectURL(file[0]);
    image.addEventListener('load', () => {
      console.log(`image width *** ${image.width}x${image.height} and allowed ${width}x${height}`);
      if (image.width < width || image.height < height) {
        resolve(message);
      }
      resolve(undefined);
    });
    image.addEventListener('error', () => {
      reject();
    });
  });
  try {
    let msg = await calcDimension;
    return msg;
  } catch (e) {
    return 'Unable to calculate the dimension!';
  }
};

const fileSizeValidator = (maxSize, message) => (value) => {
  if (notAnEmptyFile(value)) {
    console.log(`filesize is ${value[0].size} and allowed size is ${maxSize}`);
    return value[0].size > maxSize ? message : undefined;
  }
};

export const dateRangeValidator =
  (minAllowedDuration, maxAllowedDuration, message) => (calendarDates) => {
    if (!calendarDates) return;

    let datesDiff = getRangeInDays(calendarDates);
    return datesDiff < minAllowedDuration || datesDiff > maxAllowedDuration ? message : undefined;
  };

const stepCountValidator = (maxLength, message) => (value) => {
  if (!value) return;
  return value.split('\n').length > maxLength ? message : undefined;
};

//Made reduce async so that it can resolve a promise of some async function in between the loop
export const composeValidators = (validators) => (value) => {
  return validators.reduce(
    async (error, validator) => (await error) || validator(value),
    undefined
  );
};

export const getValidators = (element) => {
  const { required, validation, type } = element;
  const validators = [];
  if (required) {
    validators.push(requiredFieldValidator);
  }

  if (validation && validation.rules) {
    validation.rules.forEach((rule) => {
      // Image dimension validation
      if (rule.resolution && rule.resolution.height && rule.resolution.width) {
        validators.push(imageDimensionValidator(rule.resolution, rule.message));
      }
      // File size validation
      if (rule.size && rule.size.size) {
        validators.push(fileSizeValidator(rule.size.size, rule.message));
      }

      // other field regex validation
      if (rule.multiLangRegex && rule.message) {
        validators.push(matchRegexValidator(getRegex(rule.multiLangRegex), rule.message));
      }
      // date validation
      if (rule.minAllowedDurationInDays && rule.maxAllowedDurationInDays) {
        validators.push(
          dateRangeValidator(
            rule.minAllowedDurationInDays,
            rule.maxAllowedDurationInDays,
            rule.message
          )
        );
      }

      // step count validation
      if (type === 'STEP_EDITOR' && rule.maxLength && rule.message) {
        validators.push(stepCountValidator(rule.maxLength, rule.message));
      }

      /**
       * Exception hard coding added for expiry date ... to be removed later
       * Expiry Date validation
       */
      //pushExpiryDateValidator(key, rule, formValues, validators);
    });
  }
  return composeValidators(validators);
};

/**
 *
 * @param {*} validationRules
 * @returns string of all cumulative accepted extensions, passed to accept attribute in file-dropzone
 */
export const getAcceptedExtensions = (validationRules) => {
  // fallback to let you upload the allowed extensions
  const defaultAcceptedExt = 'image/*';
  let acceptedExt = '';
  notEmptyArray(validationRules) &&
    validationRules.forEach((rule) => {
      if (rule.accept && notEmptyArray(rule.accept)) {
        acceptedExt = rule.accept.map((ext) => `.${ext}`).join(',');
      }
    });
  return acceptedExt ? acceptedExt : defaultAcceptedExt;
};

export const mustbeAlphaNumeric = (label) => (value) =>
  value.match(/^[a-zA-Z0-9]+$/)
    ? undefined
    : `${label}${' '}${validationMessage.MUST_BE_ALPHANUMERIC}`;
