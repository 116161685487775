import { getUUID, getPageInformation, getHitNumber } from './utils';
import { CS_VID, CS_SID, EMPTY_STRING } from './constants';
import { getDefaultValues } from './main';
import { fireLogWithSentry } from './sentry';

/**
 * get default dimensions
 * @param {object} params
 * @returns {object}
 */
export const getDefaultParameters = (params) => {
   try {
      const performanceTiming = window.performance.timing;
      const date = new Date().toISOString().slice(0, 10).trim();
      const time = new Date(Date.now()).toLocaleString().split(',')[1].trim();
      const {
         browserHeight,
         browserWidth,
         timezone,
         networkSpeed,
         utm_source,
         utm_campaign,
         utm_medium,
         language,
         cookieSupport,
         colorDepth,
         correlationId,
         userType,
         internalCampaignId,
         campaignId,
         visitNumber,
         platform,
         browserInformation,
         isUserLoggedIn,
      } = getDefaultValues();

      const urlParam = {
         browserHeight,
         browserWidth,
         url: encodeURIComponent(location.href),
         // getting vid first, bcz userType in sid is based on vid.
         visitorId: getUUID(CS_VID), // have to update expiry on each action
         sessionId: getUUID(CS_SID),
         navigationstartTs: performanceTiming.navigationStart,
         domContentLoadTs: performanceTiming.domContentLoadedEventEnd,
         pageLoadTs: performanceTiming.loadEventEnd,
         timezone,
         networkSpeed,
         utm_source,
         utm_campaign,
         utm_medium,
         language,
         cookieSupport,
         colorDepth,
         correlationId,
         userType,
         referrer: encodeURIComponent(document.referrer),
         internalCampaignId,
         campaignId,
         platform,
         browserName: browserInformation.browserName,
         browserVersion: browserInformation.browserVersion,
         eventDate: date,
         eventTs: `${date} ${time}`,
         visitNumber,
         hitNumber: getHitNumber(params.eventAction),
         loadTimeInMilliseconds: performanceTiming.loadEventEnd,
         os: browserInformation.osName,
         osVersion: browserInformation.osVersion,
         internal_source: utm_source,
         internal_medium: utm_medium,
         internal_campaign: utm_campaign,
         isUserLoggedIn,
      };

      const currentPage = params.eventCategory || EMPTY_STRING;

      urlParam['currentPageName'] = EMPTY_STRING;
      urlParam['previousPageName'] = EMPTY_STRING;
      const pageInformation = getPageInformation(currentPage);

      if (pageInformation) {
         urlParam['currentPageName'] = pageInformation.cpn;
         urlParam['previousPageName'] = pageInformation.ppn;
      }

      return urlParam;
   } catch (error) {
      fireLogWithSentry(
         'params.ts-->getDefaultParameters-->clickstream: error while getting default dimensions',
         error
      );
   }
};
