import PropTypes from 'prop-types';
import { testIds } from '../../../constants/testId';
import styles from './Paragraph.module.scss';

const Paragraph = (props) => {
    const { text, className, variant, onClick } = props;
    return <div onClick={onClick} className={`${styles[variant]} ${className}`} data-testid={testIds['paragraph-component']}>{text}</div>
};

Paragraph.propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    variant: PropTypes.string.isRequired,
    onClick: PropTypes.func
};

export default Paragraph;