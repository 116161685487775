import { tasksStatus } from '../../molecules/TaskList/tasks.config';
export const getCurrentTaskConfig = (allTasks, tabConfig) => {
  if (!allTasks || allTasks.length < 1) {
    return [];
  }
  tabConfig = tabConfig.map((tab) => {
    let found = allTasks.find((task) => {
      return task.id == tab.id;
    });
    if (!found) {
      return null;
    }
    tab.Component = tab.Read;
    if (
      found &&
      found.status &&
      [tasksStatus.NOT_STARTED, tasksStatus.REJECTED, tasksStatus.EDITABLE].includes(found.status)
    ) {
      tab.Component = tab.Write;
    } else {
      tab.Component = tab.Read;
    }
    if (isTabDisable(allTasks, found && found.executesAfter)) {
      tab.disable = true;
    }
    return tab;
  });
  tabConfig = tabConfig.filter((el) => {
    return !!el;
  });
  return tabConfig;
};

export const isTabDisable = (allTasks, executesAfter) => {
  let disable = false;
  executesAfter &&
    executesAfter.map((execute) => {
      let found = allTasks.find((task) => {
        return task.id == execute;
      });
      if (found && found.status != tasksStatus.COMPLETED) {
        disable = true;
      }
    });
  return disable;
};
