import { useState } from 'react';
import { getGstdetails, submitGstDetails } from '../../../api/apiControllers/team.onboarding';
import { gstValidator, isEmptyOrSpaces, toKB } from '../../common/Utility/commonUtils';
import styles from './information.module.scss';
import Button from '../../atoms/Button/Button';
import { testIds } from '../../../constants/testId';
import Confirmation from './confirmation';
import { proceedToNextTask } from '../CompanyProfile/fetchCompanyData';
import { useNavigate } from 'react-router-dom';
import { notificationFail, notificationSuccess } from '../../../redux/actions/actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadTimeInterval } from '../../common/Utility/Utility';
import { sendClickStreamEvent } from '../../common/Utility/Analytics';
import SingleSelect from '../../atoms/Select/select';
import { onboardingOptions } from './msmed.config';
import OnboardingThroughPan from './OnboardingThroughPan';
const CompanyDetailsPan = ({ notificationSuccess, notificationFail }) => {
  const [error, setError] = useState({});
  const [gstInfo, setGstInfo] = useState(null);
  const [gstTimeTracking, setGstTimeTracking] = useState(null);
  const [gst, setGst] = useState('');
  const [file, setFile] = useState(null);
  const [fileData, setFileData] = useState(null);
  let navigate = useNavigate();
  const [confirmation, setConfirmation] = useState(null);
  const [onboardingThrough, setOnboardingThrough] = useState('GST');
  const submitGstInfo = async () => {
    let bodyParams = new FormData();
    bodyParams.append('file', fileData);
    bodyParams.append('docType', 'PAN');
    bodyParams.append('gstinNumber', gst);
    bodyParams.append('taskId', 'ADD_COMPANY_DETAILS_PAN_GST');
    const timeSpent = loadTimeInterval(gstTimeTracking);
    try {
      sendClickStreamEvent({
        eventAction: 'GST_INFO_TIME',
        loadTimeInMilliseconds: timeSpent
      });
      await submitGstDetails(bodyParams);
      setGstTimeTracking(null);
      setTimeout(() => {
        proceedToNextTask(navigate);
      }, 2000);
      notificationSuccess('Data Updated Successfully!!');
    } catch (e) {
      console.log(e);
    }
  };
  const setGstnumber = async (value) => {
    setGst(value);
    let gstError = gstValidator(value);
    if (gstError) {
      let err = { ...error, gstError };
      setError(err);
      setGstInfo(null);
    } else {
      setError({ ...error, gstError: null });
      try {
        let resp = await getGstdetails(value);
        const { bno, flno, bnm, st, loc, city, dst, stcd, pncd } = resp.address;
        let compAddress = '';
        compAddress =
          compAddress +
          (isEmptyOrSpaces(bno) ? '' : bno + ', ') +
          (isEmptyOrSpaces(flno) ? '' : flno + ', ') +
          (isEmptyOrSpaces(bnm) ? '' : bnm + ', ') +
          (isEmptyOrSpaces(st) ? '' : st + ', ') +
          (isEmptyOrSpaces(loc) ? '' : loc + ', ') +
          (isEmptyOrSpaces(city) ? '' : city + ', ') +
          (isEmptyOrSpaces(dst) ? '' : dst + ', ') +
          (isEmptyOrSpaces(stcd) ? '' : stcd + ', ') +
          (isEmptyOrSpaces(pncd) ? '' : pncd + ', ');

        if (
          compAddress &&
          compAddress.length > 1 &&
          compAddress[compAddress.length - 1] === ' ' &&
          compAddress[compAddress.length - 2] === ','
        )
          compAddress = compAddress.substring(0, compAddress.length - 2);

        setGstInfo({ ...resp, compAddress });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleImage = (event) => {
    let accept = '.png,.jpg,.jpeg';
    const { files } = event && event.target;
    if (
      files &&
      files[0].name &&
      !accept
        .split(',')
        .includes('.' + files[0].name.split('.')[files[0].name.split('.').length - 1].toLowerCase())
    ) {
      notificationFail({ message: `File extension should be one of ${accept}` });
      return;
    }
    setFile({ fileName: files[0].name, fileSize: toKB(files[0].size) });
    setFileData(files[0]);
  };
  const checkDisabled = () => {
    return !(fileData != null && gst != null);
  };

  return (
    <div className={styles['information']} data-testid={testIds['information-component']}>
      <div className={styles.selectGrp}>
        <div className={styles.selectLabel}>Select Onboarding Through</div>
        <SingleSelect
          options={onboardingOptions}
          value={onboardingThrough}
          onChange={(e) => {
            setOnboardingThrough(e.target.value);
          }}
        />
      </div>
      {onboardingThrough === 'GST' ? (
        <>
          <fieldset>
            <div className="input-container">
              <input
                data-testid={testIds['gst-input']}
                type="text"
                minLength={15}
                maxLength={15}
                name="gstNumber"
                className="input"
                required="required"
                onChange={(e) => {
                  if (!gstTimeTracking) {
                    setGstTimeTracking(new Date().getTime());
                  }
                  setGstnumber(e.target.value && e.target.value.toUpperCase());
                }}
              />
              <label>Enter Registered GSTIN</label>
            </div>
            <div className="input-error-msg">{error.gstError}</div>
          </fieldset>
          {gstInfo && (
            <>
              <div className={styles['detailRow']}>
                <div className={styles['heading']}>Name:</div>
                <div className={styles['data']}> {gstInfo.companyName}</div>
              </div>
              <div className={styles['detailRow']}>
                <div className={styles['heading']}>Address:</div>
                <div className={styles['data']}> {gstInfo.compAddress}</div>
              </div>
              <fieldset className={styles['upload']}>
                <label className="pan-proof">Upload PAN for verification</label>
                {!file && (
                  <div className={styles['uploadWrapper']}>
                    <input
                      data-testid={testIds['file-input']}
                      type="file"
                      id="file"
                      name="uploadPan"
                      accept=".png,.jpeg,.jpg"
                      className={styles['file']}
                      onChange={handleImage}
                      //  ref={uploadInp}
                    />
                    <label className={styles['fileInstruction']}>Upload From Device</label>
                  </div>
                )}
                {file && file.fileName && (
                  <>
                    <div className={styles['uploadWrapper']}>
                      <span className="upload-proof-name">{file.fileName}</span>
                      <span className={styles['size']}>{file.fileSize}</span>
                      <i
                        className={styles['iconCross']}
                        onClick={() => {
                          setFile(null);
                          setFileData(null);
                        }}
                      >
                        x
                      </i>
                    </div>
                  </>
                )}
                <p className={styles['fileStatus']}>
                  (Please upload a JPG/JPEG/PNG, file size not exceeding 2MB)
                </p>
              </fieldset>
              <div>
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => {
                    submitGstInfo();
                  }}
                  btnText="Save and Continue"
                  disabled={checkDisabled()}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <OnboardingThroughPan />
      )}
      {confirmation && (
        <Confirmation
          goToNextStep={submitGstInfo}
          handleClose={(bool) => {
            setConfirmation(bool);
          }}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (msg) => dispatch(notificationSuccess(msg)),
  notificationFail: (msg) => dispatch(notificationFail(msg))
});

CompanyDetailsPan.propTypes = {
  notificationSuccess: PropTypes.func,
  notificationFail: PropTypes.func
};
export default connect(null, mapDispatchToProps)(CompanyDetailsPan);
