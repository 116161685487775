import React from 'react';
import { testIds } from '../../../constants/testId';
import PropTypes from 'prop-types';

import './input.scss';
const InputComponent = (props) => {
  const { input, meta, isDisable, isBigCheckedIcon, variant, ...otherProps } = props;
  return (
    <fieldset className="fieldset">
      <div className={`input-container ${isDisable && 'disabled'} ${variant}`}>
        {variant === 'bordered' && <label>{props.label}</label>}
        <input
          data-testid={testIds['input-component']}
          {...otherProps}
          type={props.type || 'text'}
          //placeholder="Bank account number"
          name={props.name}
          disabled={isDisable}
          value={props.value}
          maxLength={props.maxLength}
          //onChange={props.onChange}
          {...input}
          onChange={(ev) => {
            input.onChange(ev);
            props.onChange && props.onChange(ev);
          }}
          required={true} // This does not have any impact just required for material behaviour
          className="input"
        />
        {variant !== 'bordered' && <label>{props.label}</label>}
        {props.loading && <div className="smallLoader" />}
        <div className="bar" />
      </div>
      {meta && meta.error && meta.touched && <span className="input-error-msg">{meta.error}</span>}
      {isBigCheckedIcon && <div className="input_big_checked_icon"></div>}
    </fieldset>
  );
};
export default InputComponent;

InputComponent.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  isDisable: PropTypes.bool,
  isBigCheckedIcon: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  label: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.string,
  variant: PropTypes.string
};
