import { apiUrls } from '../../constants/apiUrls';
import { handlePromise } from '../helpers';
import ENV from '../../environments';

export const saveUserProfile = async (params) => {
  const apiPayload = {
    method: 'POST',
    url: ENV.getTeamIAMUrl() + apiUrls.updateUserProfile,
    data: params
  };

  return handlePromise(apiPayload);
};
