import DatePicker from 'react-date-picker';
import PropTypes from 'prop-types';

import './Datepicker.scss';
import calendarIcon from '../../../assets/images/svg/Vector.svg';
import { testIds } from '../../../constants/testId';

const DatePickerComponent = ({ value, onChange, ...otherProps }) => {
  return (
    <div data-testid={testIds['date-picker-component']}>
      <DatePicker
        onChange={onChange}
        value={value}
        calendarClassName="calendar-dialog"
        className="calendar-wrapper"
        monthPlaceholder="MM"
        dayPlaceholder="DD"
        yearPlaceholder="YYYY"
        calendarIcon={<img src={calendarIcon} />}
        {...otherProps}
      />
    </div>
  );
};

DatePickerComponent.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default DatePickerComponent;
