// just for internal reference
export const DEFAULT_DIMENSIONS = {
   appVersion: 'appVersion',
   browserHeight: 'browserHeight',
   browserName: 'browserName',
   browserVersion: 'browserVersion',
   browserWidth: 'browserWidth',
   campaignId: 'campaignId',
   channel: 'channel',
   colorDepth: 'colorDepth',
   cookieSupport: 'cookieSupport',
   correlationId: 'correlationId',
   createdTime: 'createdTime',
   ctaTs: 'ctaTs',
   date: 'date',
   deviceId: 'deviceId',
   deviceName: 'deviceName',
   domContentLoadTs: 'domContentLoadTs',
   eventDate: 'eventDate',
   eventTs: 'eventTs',
   firstTouchChannel: 'firstTouchChannel',
   gaid: 'gaid',
   hitNumber: 'hitNumber',
   id: 'id',
   internalCampaignId: 'internalCampaignId',
   internal_campaign: 'internal_campaign',
   internal_medium: 'internal_medium',
   internal_source: 'internal_source',
   ipAddress: 'ipAddress',
   isUserLoggedIn: 'isUserLoggedIn',
   loadTimeInMilliseconds: 'loadTimeInMilliseconds',
   navigationstartTs: 'navigationstartTs',
   networkSpeed: 'networkSpeed',
   os: 'os',
   osName: 'osName',
   osVersion: 'osVersion',
   pageLoadTs: 'pageLoadTs',
   platform: 'platform',
   referrer: 'referrer',
   sessionId: 'sessionId',
   timezone: 'timezone',
   ucid: 'ucid',
   url: 'url',
   userType: 'userType',
   utm_campaign: 'utm_campaign',
   utm_medium: 'utm_medium',
   utm_source: 'utm_source',
   visitNumber: 'visitNumber',
   visitorId: 'visitorId',
};

export const GLOBAL_DIMENSIONS = {
   appNumber: 'appNumber',
   broadbandCount: 'broadbandCount',
   circle: 'circle',
   city: 'city',
   customLabel: 'customLabel',
   customValue: 'customValue',
   customerTier: 'customerTier',
   dthCount: 'dthCount',
   eventAction: 'eventAction',
   eventCategory: 'eventCategory',
   eventLabel: 'eventLabel',
   eventValue: 'eventValue',
   homesId: 'homesId',
   horizontalPosition: 'horizontalPosition',
   isInteractive: 'isInteractive',
   isMember: 'isMember',
   isOneAirtel: 'isOneAirtel',
   journey: 'journey',
   language: 'language',
   latitude: 'latitude',
   longitude: 'longitude',
   msisdn: 'msisdn',
   newApp: 'newApp',
   newInstrumentation: 'newInstrumentation',
   oapbbCount: 'oapbbCount',
   oapdthCount: 'oapdthCount',
   oappostCount: 'oappostCount',
   postpaidCount: 'postpaidCount',
   prepaidCount: 'prepaidCount',
   previousPageName: 'previousPageName',
   primaryLob: 'primaryLob',
   primaryNumberType: 'primaryNumberType',
   secondaryLob: 'secondaryLob',
   secondaryMsisdn: 'secondaryMsisdn',
   secondaryNumberType: 'secondaryNumberType',
   section: 'section',
   status: 'status',
   statusIndicator: 'statusIndicator',
   subSection: 'subSection',
   timeSpent: 'timeSpent',
   verticalPosition: 'verticalPosition',
};

// just for internal reference
export const CUSTOM_DIMENSIONS = {
   abVariable: 'abVariable',
   accountId: 'accountId',
   addressSelectionType: 'addressSelectionType',
   airtelWeekend: 'airtelWeekend',
   arp: 'arp',
   basePackId: 'basePackId',
   benefitAmount: 'benefitAmount',
   bundleId: 'bundleId',
   cardType: 'cardType',
   cartId: 'cartId',
   category: 'category',
   childMsisdn: 'childMsisdn',
   childSimType: 'childSimType',
   cityAutoPick: 'cityAutoPick',
   cityChanged: 'cityChanged',
   cityResolutionType: 'cityResolutionType',
   citySource: 'citySource',
   cmsPageId: 'cmsPageId',
   connectionType: 'connectionType',
   contentGenre: 'contentGenre',
   contentLanguage: 'contentLanguage',
   contentPackageId: 'contentPackageId',
   contentPercentage: 'contentPercentage',
   contentSource: 'contentSource',
   couponCode: 'couponCode',
   currentPageName: 'currentPageName',
   customCampaignId: 'customCampaignId',
   customerCohort: 'customerCohort',
   customerSegmentId: 'customerSegmentId',
   devicePrice: 'devicePrice',
   deviceType: 'deviceType',
   discount: 'discount',
   email: 'email',
   error: 'error',
   extraData1: 'extraData1',
   extraData10: 'extraData10',
   extraData11: 'extraData11',
   extraData12: 'extraData12',
   extraData13: 'extraData13',
   extraData14: 'extraData14',
   extraData15: 'extraData15',
   extraData16: 'extraData16',
   extraData17: 'extraData17',
   extraData18: 'extraData18',
   extraData19: 'extraData19',
   extraData2: 'extraData2',
   extraData20: 'extraData20',
   extraData3: 'extraData3',
   extraData4: 'extraData4',
   extraData5: 'extraData5',
   extraData6: 'extraData6',
   extraData7: 'extraData7',
   extraData8: 'extraData8',
   extraData9: 'extraData9',
   feasibilityBucket: 'feasibilityBucket',
   feedData1: 'feedData1',
   feedData2: 'feedData2',
   feedData3: 'feedData3',
   feedData4: 'feedData4',
   feedData5: 'feedData5',
   feedUserType: 'feedUserType',
   freeInvitesLeft: 'freeInvitesLeft',
   gender: 'gender',
   googleClickId: 'googleClickId',
   googleClickKeyword: 'googleClickKeyword',
   homepageCitySelection: 'homepageCitySelection',
   isHighRise: 'isHighRise',
   leadAddress: 'leadAddress',
   leadEmail: 'leadEmail',
   leadExtraData: 'leadExtraData',
   leadId: 'leadId',
   leadName: 'leadName',
   leadRtn: 'leadRtn',
   map: 'map',
   metaData: 'metaData',
   noOfChildren: 'noOfChildren',
   notificationMessageId: 'notificationMessageId',
   notificationStatus: 'notificationStatus',
   numberOfFloors: 'numberOfFloors',
   offerId: 'offerId',
   offerSource: 'offerSource',
   oneAirtelsLob: 'oneAirtelsLob',
   pageVersion: 'pageVersion',
   partnerId: 'partnerId',
   paymentFlow: 'paymentFlow',
   paymentStack: 'paymentStack',
   paymentType: 'paymentType',
   pincode: 'pincode',
   planName: 'planName',
   planRental: 'planRental',
   postingId: 'postingId',
   primaryAddedAccounts: 'primaryAddedAccounts',
   productId: 'productId',
   prop0: 'prop0',
   prop10: 'p10',
   publicWifiAttach: 'publicWifiAttach',
   savedCards: 'savedCards',
   secondaryAddedAccounts: 'secondaryAddedAccounts',
   serviceId: 'serviceId',
   slotDate: 'slotDate',
   slotTime: 'slotTime',
   speechIcon: 'speechIcon',
   subCategory: 'subCategory',
   targetPlan: 'targetPlan',
   thanksBenefitDetails: 'thanksBenefitDetails',
   thirdPartyPartnerId: 'thirdPartyPartnerId',
   ticketId: 'ticketId',
   totalDuration: 'totalDuration',
   transactionAmount: 'transactionAmount',
   transactionId: 'transactionId',
   transactionMode: 'transactionMode',
   transactionSource: 'transactionSource',
   upgrade: 'upgrade',
   userHierarchy: 'userHierarchy',
   vasCode: 'vasCode',
   walletAmount: 'walletAmount',
};
