import { ACTIONS } from '../../constants/constants';

const companyProfileReducer = function (state = {}, action) {
  switch (action.type) {
    case ACTIONS.SET_COMPANY_PROFILE:
      return action.data;
    default:
      return state;
  }
};

export default companyProfileReducer;
