const NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  VERIFICATION_IN_PROGRESS = 'VERIFICATION_IN_PROGRESS',
  REJECTED = 'REJECTED',
  EDITABLE = 'EDITABLE';

export const tasksStatus = {
  NOT_STARTED,
  IN_PROGRESS,
  COMPLETED,
  REJECTED,
  VERIFICATION_IN_PROGRESS,
  EDITABLE
};
