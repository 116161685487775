import { trackEvent } from '@airtel-web/clickstream';
import { ENVIRONMENT } from '../../../constants/constants';
import ENV from "../../../environments";

const clickStreamEventNew = (obj) => {
    if(trackEvent) trackEvent(obj);
}

export const sendClickStreamEvent = async (data) => {
    const { msisdn,
        section,
        subsection,
        eventAction,
        eventLabel,
        eventValue,
        customValue,
        customLabel,
        isInteractive,
        loadTimeInMilliseconds,
        horizontalPosition,
        verticalPosition,
        timeSpent } = data;
    let trackingObject = {};
    trackingObject.journey = "teams onboard";
    trackingObject.eventCategory = "teams onboard";
    trackingObject.section = section || "";
    trackingObject.subSection = subsection || "";
    trackingObject.eventAction = eventAction || "";
    trackingObject.msisdn = msisdn || "";
    trackingObject.eventLabel = eventLabel || "";
    trackingObject.eventValue = eventValue || "";
    trackingObject.customValue = customValue || "";
    trackingObject.customLabel = customLabel || "";
    trackingObject.isInteractive = isInteractive || "0";
    trackingObject.loadTimeInMilliseconds = loadTimeInMilliseconds || "0";
    trackingObject.horizontalPosition = horizontalPosition || "-1";
    trackingObject.verticalPosition = verticalPosition || "-1";
    trackingObject.timeSpent = timeSpent || "0";
    trackingObject.platform = "dweb";

    if (ENV.getEnvironment() === ENVIRONMENT.PROD) {
        clickStreamEventNew(trackingObject);
    }
};

  