import { Provider } from 'react-redux';
import { init } from '@airtel-web/clickstream';
import './styles/common/common.scss';
import './App.module.scss';
import AppRoutes from './routes';
import store from './redux/store';
import { useEffect, useState } from 'react';
import { fetchEncryptionData } from './components/common/Utility/Utility';
import { initializeApp } from './initializeApp';

const intializeAnalytics = () => {
  init();
};

const App = () => {
  const [initialized, setInitialized] = useState(null);
  useEffect(() => {
    fetchEncryptionData();
  });

  const initApp = async () => {
    await initializeApp();
    setInitialized(true);
  };

  useEffect(() => {
    initApp();
    intializeAnalytics();
  }, []);

  return (
    <>
      {initialized && (
        <Provider store={store}>
          <AppRoutes />
        </Provider>
      )}
    </>
  );
};

export default App;
