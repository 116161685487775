import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux';

import createSagaMiddleware from 'redux-saga';
import companyProfileReducer from './reducers/companyProfile';
import loaderReducer from './reducers/loaderReducer';
import notificationReducer from './reducers/errorReducer';
import selectedPartnershipReducer from './reducers/selectedPartnershipReducer';
import rootSaga from './sagas';
import landingPagePartnership from './reducers/landingPagePartnershipReducer';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  combineReducers({
    loadingState: loaderReducer,
    companyProfileState: companyProfileReducer,
    notificationReducer: notificationReducer,
    selectedPartnershipReducer: selectedPartnershipReducer,
    landingPagePartnershipReducer: landingPagePartnership
  }),
  applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(rootSaga);
export default store;
