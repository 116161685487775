import { ACTIONS } from '../../constants/constants';

const notificationReducer = function (state = {}, action) {
  switch (action.type) {
    case ACTIONS.SET_NOTIFICATION:
      return action.data;
    case ACTIONS.CLEAR_NOTIFICATION:
      return { type: null, message: null };
    default:
      return state;
  }
};

export default notificationReducer;
