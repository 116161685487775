import { connect } from "react-redux";
import PropTypes from "prop-types";
import { testIds } from "../../../constants/testId";
const ReadInformation = ({ companyProfileState }) => {
    const getAddressString = (address) => {
        const addressParam = [
            "buildingNumber",
            "line1",
            "line2",
            "line3",
            "landmark",
            "city",
            "district",
            "state",
            "country",
            "pincode",
        ];
        let addressStr = addressParam.map(
            (param) => address[param] && address[param].trim()
        );

        return addressStr.filter((el) => el).join(", ");
    };

    let { addresses, tradeName, gst, pan } = companyProfileState;
    return (
        <div data-testid={testIds["read-information"]}>
            <div className="setting-row">
                <span>Name: </span>
                <span> {tradeName}
                </span>
            </div>
            <div className="setting-row">
                <span>Address: </span>
                <span>{addresses && getAddressString(addresses[0])}
                </span>
            </div>
            {gst && <div className="setting-row">
                <span>GSTIN: </span>
                <span>{gst}
                </span>
            </div>}
            {pan && <div className="setting-row">
                <span>PAN : </span>
                <span>{pan}
                </span>
            </div>}

        </div>
    )

}


ReadInformation.propTypes = {
    companyProfileState: PropTypes.object
}
const mapStateToProps = (state) => ({
    companyProfileState: state.companyProfileState
});

export default connect(mapStateToProps, null)(ReadInformation);
