import { fireLogWithSentryBase } from '@airtel-lego/sentry';

/**
 * wrapper function for sentry logging in case of error
 * @param {string} errorMsg - Custom error string
 * @param {object} errorException - thrown error/ exception
 * @param {object} contextValues - object with context information for Sentry.captureException method
 */
export const fireLogWithSentry = (
   errorMsg: string,
   errorException: any = {},
   contextValues: { tags?: any; extra?: any; [key: string]: any } = {}
) => {
   const _tags: { [key: string]: string } = { lib: 'clickstream' };

   const _contextValues = { ...contextValues, tags: { ...contextValues.tags, ..._tags } };

   const apiErrorInfo = { errorCode: 'errorcode', errorMessage: 'errortitle' };
   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
   // @ts-ignore
   if (BUILD_TYPE == 'module') {
      fireLogWithSentryBase(errorMsg, errorException, _contextValues, apiErrorInfo);
   }
};
