import { ACTIONS } from '../../constants/constants';

const landingPagePartnership = function (state = null, action) {
  switch (action.type) {
    case ACTIONS.SET_LANDING_PAGE_PARTNERSHIP:
      return action.data;
    default:
      return state;
  }
};

export default landingPagePartnership;
