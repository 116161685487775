export const testIds = {
  'app-component': 'app-component',
  'get-started-component': 'get-started-component',
  'paragraph-component': 'paragraph-component',
  'card-component': 'card-component',
  'button-component': 'button-component',
  'heading-component': 'heading-component',
  'loader-component': 'loader-component',
  'dashboard-component': 'dashboard-component',
  'graph-component': 'graph-component',
  'select-partnership-component': 'select-partnership-component',
  'header-component': 'header-component',
  'quick-links-component': 'quick-links-component',
  'task-component': 'task-component',
  'task-list-component': 'task-list-component',
  'modal-component': 'modal-component',
  'close-modal': 'close-modal',
  dashboard: 'dashboard-component',
  'dashboard-rendered': 'dashboard-rendered',
  'toggle-menu': 'toggle-menu',
  'user-logout': 'user-logout',
  'bank-details-component': 'bank-details-component',
  'business-details-component': 'business-details-component',
  'company-profile': 'company-profile',
  'information-component': 'information-component',
  'input-component': 'input-component',
  'text-area-component': 'text-area-component',
  'dropdown-component': 'dropdown-component',
  'select-component': 'select-component',
  'tabs-component': 'tabs-component',
  'bank-details-account': 'bank-details-account',
  'bank-details-account-confirm': 'bank-details-account-confirm',
  'bank-details-account-name': 'bank-details-account-name',
  'bank-details-ifsc': 'bank-details-ifsc',
  'bank-details-submit': 'bank-details-submit',
  'menu-item-component': 'menu-item-component',
  'business-details-reset-button': 'business-details-reset-button',
  'gst-input': 'gst-input',
  'file-input': 'file-input',
  'toggled-div': 'toggled-div',
  'profile-select': 'profile-select',
  'read-bank-details-component': 'read-bank-details-component',
  'penny-drop-modal': 'penny-drop-modal',
  onProceed: 'onProceed',
  'signup-component': 'signup-component',
  'signup-btn': 'signup-btn',
  'graph-config': 'graph-config',
  'error-modal': 'error-modal',
  'notification-modal': 'notification-modal',
  'notification-modal-close': 'notification-modal-close',
  'select-partnership': 'select-partnership',
  'checkbox-group-component': 'checkbox-group-component',
  'confirmation-modal': 'confirmation-modal',
  'next-step-button': 'next-step-button',
  'policy-component': 'policy-component',
  'policy-submit': 'policy-submit',
  'user-profile-page': 'user-profile-page',
  'user-profile-submit': 'user-profile-submit',
  'user-details-reset-button': 'user-details-reset-button',
  'change-pass-fields': 'change-pass-fields',
  'card-summary': 'card-summary',
  'modal-close': 'modal-close',
  'invoice-component': 'invoice-component',
  'receipts-component': 'receipts-component',
  'receipts-summary-component': 'receipts-summary-component',
  'date-picker-component': 'date-picker-component',
  'reporting-component': 'reporting-component',
  'mandate-html': 'mandate-html',
  'otp-field': 'otp-field',
  'password-field': 'password-field',
  'submit-otp': 'submit-otp',
  'login-page': 'login-page',
  'login-header': 'login-header',
  'tool-top-component': 'tool-top-component',
  'file-input-wrapper': 'file-input-wrapper',
  'forgot-password-wrapper': 'forgot-password-wrapper',
  'update-password-wrapper': 'update-password-wrapper',
  'radio-component-wrapper': 'radio-component-wrapper',
  'input-group-wrapper': 'input-group-wrapper',
  'login-form-wrapper': 'login-form-wrapper',
  'forgot-pwd': 'forgot-pwd',
  'read-information': 'read-information',
  'onboard-pan-submit': 'onboard-pan-submit',
  'input-grp': 'input-grp',
  'info-card': 'info-card',
  'landing-page': 'landing-page',
  'info-section': 'info-section',
  'header-component-section': 'header-component-section',
  'testimonial-slider': 'testimonial-slider',
  'footer-navigation': 'footer-navigation',
  'footer-wrapper': 'footer-wrapper',
  'get-in-touch-wrapper': 'get-in-touch-wrapper'
};
