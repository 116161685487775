import { Field, Form } from 'react-final-form';
import Input from '../../atoms/Input/input';
import {
  composeValidators,
  minLengthValidator,
  mustbeAlphaNumeric,
  requiredFieldValidator,
  ifscValidator
} from '../../common/Utility/validator';
import Button from '../../atoms/Button/Button';
import { fetchByIfsc, submitBankDetails } from '../../../api/apiControllers/team.onboarding';
import { testIds } from '../../../constants/testId';
import { useState } from 'react';
import BankDetailModal from './BankDetailsModal';
import { proceedToNextTask } from '../CompanyProfile/fetchCompanyData';
import { getEncryptedMessage, loadTimeInterval } from '../../common/Utility/Utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sendClickStreamEvent } from '../../common/Utility/Analytics';
import './bankDetails.scss'
import validationMessage from '../../common/Utility/validationMessage';
const BankDetails = ({ companyProfileState }) => {
  const [ifscData, setIfscData] = useState(null);
  const [show, setShow] = useState(false);
  const [bankDetailsTimeTracking, setBankDetailsTimeTracking] = useState(null);
  const [bankDetailResponse, setBankDetailResponse] = useState(null);
  let navigate = useNavigate();
  const submitDetails = async (values) => {
    const timeSpent = loadTimeInterval(bankDetailsTimeTracking);
    const { tradeName } = companyProfileState;
    try {
      let resp = await submitBankDetails({
        // ...values,
        bankAddress: ifscData.address,
        bankName: ifscData.bank,
        // accountType: "SAVING",
        //companyUuid: '62e26ea2ffcaccbc4838a172',
        bankAccountNumber: getEncryptedMessage(values['bankAccountNumber']),
        ifsc: values['ifscCode'] && values['ifscCode'].toUpperCase(),
        // verificationStatus: "COMPLETED",
        isActive: 'true',
        accountHolderName: tradeName
      });
      setBankDetailResponse({ ...resp, ...values, bankName: ifscData.bank });
      sendClickStreamEvent({ eventAction: "BANK_DETAILS_TIME", loadTimeInMilliseconds: timeSpent });
      setBankDetailsTimeTracking(null);
      setShow(true);
    } catch (e) {
      console.log(e);
    }
  };

  const validateConfirmAccount = (values) => {
    return (
      values.bankAccountNumber &&
      values.bankAccountNumber.match(/^[a-zA-Z0-9]+$/) &&
      values.bankAccountNumber === values.confirmBankAccountNumber &&
      values.bankAccountNumber.length > 8 &&
      values.confirmBankAccountNumber.length > 8
    );
  };

  const getIfscData = async (ifsc) => {
    try {
      let data = await fetchByIfsc(ifsc);
      setIfscData(data);
    } catch (e) {
      setIfscData(null);
      console.log(e);
    }
  };

  const getInitialValues = () => {
    let { bankDetails, tradeName } = companyProfileState;
    if (bankDetails && bankDetails[0]) {
      let ifsc = bankDetails[0].ifscCode;
      !ifscData && getIfscData(ifsc);
      return {
        bankAccountNumber: bankDetails[0].accountNumber,
        confirmBankAccountNumber: bankDetails[0].accountNumber,
        ifscCode: ifsc,
        accountHolderName: bankDetails[0].beneficiaryName
      };
    }
    else {
      return { accountHolderName: tradeName }
    }
  };
  return (
    <div className={'bankDetail'} data-testid={testIds['bank-details-component']}>
      <Form
        onSubmit={(values) => submitDetails(values)}
        initialValues={getInitialValues()}
        render={({ handleSubmit, values, hasValidationErrors }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Field
              data-testid={testIds['bank-details-account']}
              required={true}
              label="Bank Account Number"
              component={Input}
              type="password"
              name="bankAccountNumber"
              key="bankAccountNumber"
              maxLength={18}
              validate={composeValidators([
                requiredFieldValidator,
                mustbeAlphaNumeric('Account Number'),
                minLengthValidator(8, 'Account Number')
              ])}
              onChange={() => {
                if (!bankDetailsTimeTracking) {
                  setBankDetailsTimeTracking(new Date().getTime());
                }
              }}
            />
            <div>
              <Field
                data-testid={testIds['bank-details-account-confirm']}
                required={true}
                label="Confirm Bank Account Number"
                component={Input}
                name="confirmBankAccountNumber"
                key="confirmBankAccountNumber"
                maxLength={18}
                isBigCheckedIcon={validateConfirmAccount(values)}
                validate={composeValidators([
                  requiredFieldValidator,
                  mustbeAlphaNumeric('Account Number'),
                  minLengthValidator(8, 'Account Number')
                ])}
              />
              {values.bankAccountNumber && values.confirmBankAccountNumber && !validateConfirmAccount(values)
                && <span className="confirm-act-error">{validationMessage.ACT_MISMATCH}</span>}
            </div>
            <Field
              required={true}
              data-testid={testIds['bank-details-account-name']}
              label="Account Holder's Name"
              component={Input}
              maxLength={80}
              isDisable={true}
              name="accountHolderName"
              key="accountHolderName"
              validate={composeValidators([requiredFieldValidator])}
            />
            <Field
              data-testid={testIds['bank-details-ifsc']}
              required={true}
              label="IFSC Code"
              component={Input}
              name="ifscCode"
              key="ifscCode"
              maxLength={11}
              validate={composeValidators([requiredFieldValidator, ifscValidator])}
              onChange={async (e) => {
                let ifsc = e.target.value;
                if (ifsc && ifsc.length === 11) {
                  getIfscData(ifsc);
                }
              }}
            />

            <div className="actions">
              <Button
                variant="primary"
                disabled={hasValidationErrors || !ifscData || !validateConfirmAccount(values)}
                type="submit"
                btnText="Submit"
              />
            </div>
          </form>
        )}
      />
      {show && (
        <BankDetailModal
          bankDetailResponse={bankDetailResponse}
          onProcced={() => {
            proceedToNextTask(navigate);
          }}
          setShow={setShow}
        />
      )}
    </div>
  );
};

BankDetails.propTypes = {
  companyProfileState: PropTypes.object
};
const mapStateToProps = (state) => ({
  companyProfileState: state.companyProfileState
});

export default connect(mapStateToProps, null)(BankDetails);
