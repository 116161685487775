/* eslint-disable no-undef */
import { ENVIRONMENT } from './constants/constants';

const PROD = {
  baseUrl: 'https://digi-api.airtel.in/team-onboard',
  billingUrl: 'https://digi-api.airtel.in/team-billing',
  baseName: '/airtel-team',
  teamPennyDrop: 'https://digi-api.airtel.in/penny-drop',
  keyCloakAdapter: 'https://digi-api.airtel.in/auth',
  teamIamBaseUrl: 'https://digi-api.airtel.in/team-iam',
  teamCommonUrl: 'https://digi-api.airtel.in/team-common',
  teamPdfUrl: 'https://digi-api.airtel.in/team-billing/v1/download/invoice',
  lcoBasePath: 'https://digi-api.airtel.in',
  lcoUiRelativeBasePath: '/lco-admin',
  befeBaseUrl: 'https://digi-api.airtel.in/guardian/api/v1/digitalStore/public/'
};

const STAGE = {
  baseUrl: 'http://10.5.74.75:8081/team-onboard-qa2',
  billingUrl: 'http://10.5.74.75:8081/team-billing-qa2',
  baseName: '/longurl/on-boarding',
  teamPennyDrop: `http://10.5.74.75:8081/team-penny-drop-qa2`,
  keyCloakAdapter: 'http://10.5.74.73:8080/auth',
  teamIamBaseUrl: 'http://10.5.74.75:8081/team-iam-qa2',
  teamCommonUrl: 'http://10.5.74.75:8081/team-common-qa2',
  teamPdfUrl: 'https://kongqa.airtel.com/team-billing-qa2/v1/download/invoice',
  lcoBasePath: 'http://10.5.74.75:8081',
  lcoUiRelativeBasePath: '/longurl/lco-web',
  befeBaseUrl: 'https://kongqa.airtel.com/web/guardian/api/v1/'
};

const PREPROD = {
  baseUrl: 'http://10.5.74.80:8029/team-onboard',
  billingUrl: 'http://10.5.74.80:8036/team-billing',
  baseName: '/longurl/on-boarding-preprod',
  teamPennyDrop: `http://10.5.74.80:8040/team-penny-drop`,
  keyCloakAdapter: 'http://10.5.74.73:8080/auth',
  teamIamBaseUrl: 'http://10.5.74.75:8081/team-iam-qa2',
  teamCommonUrl: 'http://10.5.74.80:8081/team-common',
  teamPdfUrl: 'https://kongqa.airtel.com/team-billing-qa2/v1/download/invoice',
  lcoBasePath: 'http://10.5.74.75:8081',
  lcoUiRelativeBasePath: '/longurl/lco-web',
  befeBaseUrl: 'https://kongqa.airtel.com/web/guardian/api/v1/'
};

const QA = {
  baseUrl: 'http://10.5.246.46:7090',
  baseName: '/on-boarding',
  teamPennyDrop: `/team-penny-drop-qa2`,
  keyCloakAdapter: 'http://10.5.74.73:8080/auth',
  teamIamBaseUrl: 'http://10.5.74.75:8081/team-iam-qa2',
  teamCommonUrl: 'http://10.5.74.75:8081/team-common-qa2',
  lcoUiRelativeBasePath: '/longurl/lco-web'
};

const DEV = {
  baseUrl: 'http://localhost:9000/team-onboard',
  billingUrl: '/team-billing-qa2',
  baseName: '/on-boarding',
  keyCloakAdapter: 'http://10.5.74.73:8080/auth',
  teamPennyDrop: `/team-penny-drop-qa2`,
  teamIamBaseUrl: 'http://10.5.74.75:8081/team-iam-qa2',
  teamCommonUrl: 'http://10.5.74.75:8081/team-common-qa2',
  teamPdfUrl: 'https://kongqa.airtel.com/team-billing-qa2/v1/download/invoice',
  lcoBasePath: 'http://10.5.74.75:8081',
  lcoUiRelativeBasePath: '/longurl/lco-web',
  befeBaseUrl: 'https://kongqa.airtel.com/web/guardian/api/v1/'
};

class ENV {
  env;
  envName;
  constructor() {
    this.envName = ENVIRONMENT.DEV;
    this.env = DEV;
    if (process.env.REACT_APP_ENV === ENVIRONMENT.PROD) {
      this.env = PROD;
      this.envName = ENVIRONMENT.PROD;
    } else if (process.env.REACT_APP_ENV === ENVIRONMENT.STAGE) {
      this.env = STAGE;
      this.envName = ENVIRONMENT.STAGE;
    } else if (process.env.REACT_APP_ENV === ENVIRONMENT.QA) {
      this.env = QA;
      this.envName = ENVIRONMENT.QA;
    } else if (process.env.REACT_APP_ENV === ENVIRONMENT.PREPROD) {
      this.env = PREPROD;
      this.envName = ENVIRONMENT.PREPROD;
    }
  }

  getBaseUrl() {
    return this.env.baseUrl;
  }

  getBaseName() {
    return this.env.baseName;
  }

  getEnvironment() {
    return this.envName;
  }

  getLoginUrl() {
    return this.env.keyCloakAdapter;
  }

  getTeamIAMUrl() {
    return this.env.teamIamBaseUrl;
  }

  getTeamCommonUrl() {
    return this.env.teamCommonUrl;
  }

  getPennyDropURL() {
    return this.env.teamPennyDrop;
  }

  getBillingUrl() {
    return this.env.billingUrl;
  }

  getTeamPDFUrl() {
    return this.env.teamPdfUrl;
  }

  getEnv() {
    return this.env;
  }
  getLcoBasePath() {
    return this.env.lcoBasePath;
  }
  getLcoUiRelativeBasePath() {
    return this.env.lcoUiRelativeBasePath;
  }
  getBefeBasePath() {
    return this.env.befeBaseUrl;
  }
}

const instance = new ENV();
Object.freeze(instance);

export default instance;
