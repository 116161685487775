import PropTypes from 'prop-types';
import { testIds } from '../../../constants/testId';
import styles from './Heading.module.scss';

const Heading = (props) => {
    const { variant, text, className } = props;
    return <div className={`${styles[variant]} ${className}`} data-testid={testIds['heading-component']}>{text}</div>
};

Heading.propTypes = {
    variant: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string
};

export default Heading;