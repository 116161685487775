
import Select from "../../atoms/Select/select"
import { Field, Form } from "react-final-form"
import './businessDetails.scss'
import TextArea from "../../atoms/TextArea/textArea"
import { composeValidators, requiredFieldValidator } from "../../common/Utility/validator";
import Button from '../../atoms/Button/Button'
import { getCategoryBusiness, submitBusinessDetails } from "../../../api/apiControllers/team.onboarding";
import { useEffect, useState } from "react";
import { testIds } from "../../../constants/testId";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { notificationSuccess } from "../../../redux/actions/actions";
import { getUserLoggedInDetails } from "../../common/Utility/Utility";
const Business = ({ companyProfileState, notificationSuccess }) => {
    const [categories, setCategories] = useState(null);
    const SubmitCategoryDetails = async (values) => {
        try {
            let body = {
                companyType: values["categoryOfBusiness"],
                description: values["description"]
            }
            await submitBusinessDetails(body);
            notificationSuccess("Data Updated Successfully!!");
        }
        catch (e) {
            console.log(e);
        }
    }

    const getBusinessCategory = async () => {
        try {
            let loggedInUser = getUserLoggedInDetails();
            let resp = await getCategoryBusiness({ "USER-ID": loggedInUser.userUuid });
            let optionsArr = resp;
            optionsArr = optionsArr.map((item) => {
                return {
                    key: item.type,
                    displayName: item.type,
                };
            });
            setCategories(optionsArr);
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getBusinessCategory();
    }, [])
    const getInitialValues = () => {
        if (companyProfileState && companyProfileState.categoryOfBusiness) {
            let { companyType, description } = companyProfileState.categoryOfBusiness;
            return {
                description: description,
                categoryOfBusiness: companyType
            };
        }
    };
    return (
        <div className={"businessDetail"} data-testid={testIds["business-details-component"]} >
            <Form
                onSubmit={(values) => SubmitCategoryDetails(values)}
                initialValues={getInitialValues()}
                render={({
                    handleSubmit,
                    form,
                    hasValidationErrors
                }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Field
                            required={true}
                            label="Category Of Business"
                            component={Select}
                            options={categories}
                            name="categoryOfBusiness"
                            key="categoryOfBusiness"
                            validate={composeValidators([requiredFieldValidator])}

                        />
                        <Field
                            required={true}
                            label="Description"
                            component={TextArea}
                            name="description"
                            key="description"
                            validate={composeValidators([requiredFieldValidator])}
                        />
                        <div className="actions">
                            <div onClick={(e) => {
                                e.preventDefault();
                                form.reset();
                            }} className="button-inline"
                                data-testid={testIds["business-details-reset-button"]}
                            >
                                <Button variant="primary-white"
                                    btnText="Cancel" />
                            </div>
                            <Button variant="primary" disabled={hasValidationErrors} type="submit" btnText="Update" />
                        </div>

                    </form>)}
            />
        </div>
    )
}

Business.propTypes = {
    companyProfileState: PropTypes.object,
    notificationSuccess: PropTypes.func
}
const mapStateToProps = (state) => ({
    companyProfileState: state.companyProfileState
});
const mapDispatchToProps = (dispatch) => ({
    notificationSuccess: (msg) => dispatch(notificationSuccess(msg)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Business);