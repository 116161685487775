import { Suspense } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from './components/common/Loader/Loader';
import { PATHS } from './constants/paths';
import ENV from './environments';
import CompanyProfile from './components/organisms/CompanyProfile/companyProfile';
import * as LazyComponents from './loadables';
import AuthProvider from './auth';
import NotificationModal from './components/organisms/NotificationModal';
//import Policy from './components/organisms/Policy/Policy';
import UserProfile from './components/organisms/UserProfile';

const AppRoutes = (props) => {
  return (
    <>
      <NotificationModal />
      <Router basename={ENV.getBaseName()}>
        {props.loadingState && <Loader />}
        <Suspense
          fallback={
            <div className={'pos-rel top-overlay'}>
              <Loader />
            </div>
          }
        >
          <Routes>
            <Route path={'/'} element={<LazyComponents.LandingPage />} />
            <Route path={PATHS.login} element={<LazyComponents.Login />} />
            <Route path={PATHS.vendorLogin} element={<LazyComponents.Login />} />
            <Route path={PATHS.forgotPassword} element={<LazyComponents.ForgotPassword />} />
            <Route path={PATHS.updatePassword} element={<LazyComponents.UpdatePassword />} />
            <Route path={PATHS.vendorForgotPassword} element={<LazyComponents.ForgotPassword />} />
            <Route path={PATHS.vendorUpdatePassword} element={<LazyComponents.UpdatePassword />} />
            <Route
              path={PATHS.selectPartnership}
              element={
                <AuthProvider>
                  <LazyComponents.SelectPartnershipComponentLazy />
                </AuthProvider>
              }
            />
            <Route
              path={PATHS.getStarted}
              element={
                <AuthProvider>
                  <LazyComponents.GetStartedComponentLazy />
                </AuthProvider>
              }
            />
            <Route
              path={PATHS.dashboard}
              element={
                <AuthProvider>
                  <LazyComponents.DashboardWrapperComponentLazy />
                </AuthProvider>
              }
            >
              <Route index element={<LazyComponents.DashboardComponentLazy />} />
              <Route path={'/dashboard/right'} element={<div>Right Content</div>} />
              <Route
                path={PATHS.profile}
                element={
                  <AuthProvider>
                    <CompanyProfile />
                  </AuthProvider>
                }
              />
              <Route
                path={PATHS.policy}
                element={
                  <AuthProvider>
                    <LazyComponents.PolicyComponentLazy />
                  </AuthProvider>
                }
              />
              <Route
                path={PATHS.reporting}
                element={
                  <AuthProvider>
                    <LazyComponents.ReportingComponentLazy />
                  </AuthProvider>
                }
              />
              <Route
                path={PATHS.invoices}
                element={
                  <AuthProvider>
                    <LazyComponents.InvoicesComponentLazy />
                  </AuthProvider>
                }
              />
              <Route
                path={PATHS.receipts}
                element={
                  <AuthProvider>
                    <LazyComponents.ReceiptsComponentLazy />
                  </AuthProvider>
                }
              />
              <Route
                path={PATHS.userProfile}
                element={
                  <AuthProvider>
                    <UserProfile />
                  </AuthProvider>
                }
              />
            </Route>
          </Routes>
        </Suspense>
      </Router>
    </>
  );
};

AppRoutes.propTypes = {
  loadingState: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    loadingState: state.loadingState
  };
};

export default connect(mapStateToProps, null)(AppRoutes);
