import { getBrowserInformation, getUrlParam, getVisitNumber, isMobileApp } from './utils';
import {
   CID,
   EMPTY_STRING,
   ICID,
   NEW,
   UTM_CAMPAIGN,
   UTM_MEDIUM,
   UTM_SOURCE,
} from './constants';
import { fireLogWithSentry } from './sentry';

let browserHeight,
   browserWidth,
   timezone,
   networkSpeed,
   utm_source,
   utm_campaign,
   utm_medium,
   language,
   cookieSupport,
   colorDepth,
   correlationId,
   internalCampaignId,
   campaignId,
   visitNumber,
   platform,
   browserInformation,
   isUserLoggedIn,
   userType;

/**
 * set default values for future use in other functions
 * @param {string} _correlationId
 */
export const setDefaultValues = (_correlationId: string) => {
   try {
      browserHeight =
         window.innerHeight ||
         document.documentElement.clientHeight ||
         document.body.clientHeight;
      browserWidth =
         window.innerWidth ||
         document.documentElement.clientWidth ||
         document.body.clientWidth;
      timezone = new Date().getTimezoneOffset();
      networkSpeed = navigator?.connection?.effectiveType || EMPTY_STRING;
      utm_source = getUrlParam(UTM_SOURCE) || EMPTY_STRING;
      utm_campaign = getUrlParam(UTM_CAMPAIGN) || EMPTY_STRING;
      utm_medium = getUrlParam(UTM_MEDIUM) || EMPTY_STRING;
      language = window.navigator.language;
      cookieSupport = window.navigator.cookieEnabled;
      colorDepth = window.screen.colorDepth;
      correlationId = _correlationId;
      internalCampaignId = getUrlParam(ICID) || EMPTY_STRING;
      campaignId = getUrlParam(CID) || EMPTY_STRING;
      visitNumber = getVisitNumber();
      platform = isMobileApp();
      browserInformation = getBrowserInformation();
      isUserLoggedIn = platform === 'APP' ? true : EMPTY_STRING; //if platform == WEB we don't know if loggedIn or not; so leave blank
   } catch (error) {
      fireLogWithSentry(
         'index.ts-->setDefaultValues-->error occurred in clickstream sdk',
         error
      );
   }
};

/**
 * set user type for global context
 * @param {string} type
 */
export const setUserType = (type = NEW) => (userType = type);

/**
 * returns default values to other functions
 * @returns {object}
 */
export const getDefaultValues = () => {
   return {
      browserHeight,
      browserWidth,
      timezone,
      networkSpeed,
      utm_source,
      utm_campaign,
      utm_medium,
      language,
      cookieSupport,
      colorDepth,
      correlationId,
      internalCampaignId,
      campaignId,
      visitNumber,
      platform,
      browserInformation,
      isUserLoggedIn,
      userType,
   };
};
