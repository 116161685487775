import { getDashboardData } from "./api/DashboardAPI";
import { getAllPartnershipData } from "./api/GetAllPartnershipAPI";
import { getUserLoggedInDetails, setInLocalStorage, setInSessionStorage } from "./components/common/Utility/Utility";

export const initializeApp = async () => {
    try {
        const loggedInUser = getUserLoggedInDetails();
        if (loggedInUser && loggedInUser.appId) {
            const response = await getAllPartnershipData();
            let isActivePartnership = false;
            let activePartnerships = response["ACTIVE"] ? response["ACTIVE"] : [];
            let inProgressPartnerships = response['IN_PROGRESS'] ? response['IN_PROGRESS'] : [];
            const availPartnerships = [...activePartnerships, ...inProgressPartnerships];
            let partnership = availPartnerships.find((el) => {
                return el.name == loggedInUser.appId;
            });
            activePartnerships.map((part) => {
                if (part.name === loggedInUser.appId) {
                    isActivePartnership = true;
                }
            })
            if (partnership) {
                partnership = { ...partnership, isActivePartnership }
                setInSessionStorage('selectedPartnership', JSON.stringify(partnership));
            }
            const dashboardData = await getDashboardData({ "partnership-id": partnership.id });
            setInLocalStorage('company', 'details', {
                id: dashboardData && dashboardData.userProfile && dashboardData.userProfile.companyId,
                partnerShipDetails: dashboardData.partnerShipDetails,
            });
        }
    } catch (e) {
        console.log("failed to init app", e);
    }
}