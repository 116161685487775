import { FORM_TYPES } from '../../../constants/constants';
import DatePickerComponent from '../../atoms/DatePicker/DatePicker';
import SingleSelect from '../../atoms/Select/select';

export const notEmptyArray = (obj) => {
  return obj instanceof Array && obj.length !== 0;
};
export const notAnEmptyFile = (field) => {
  return notEmptyArray(field) && field[0] instanceof File;
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const gstValidator = (value) => {
  let gstError = null;
  if (typeof value === 'undefined' || value.length == 0) {
    gstError = 'GSTIN is mandatory';
  } else {
    if (
      !value.match(
        /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-8]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/
      )
    ) {
      gstError = 'Invalid GST Format!';
    } else {
      gstError = '';
    }
  }
  return gstError;
};

export const panValidator = (value) => {
  const regxpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

  if (regxpan.test(value)) {
    return true;
  }

  return false;
};

export const panValidatorWithError = (value) => {
  if (panValidator(value)) {
    return null;
  }
  return 'Invalid PAN format!';
};

export const validatePincode = (value) => {
  const pincodeRe = /^[1-9][0-9]{5}$/;

  if (pincodeRe.test(value)) {
    return null;
  }

  return 'Invalid Pincode!';
};
export const OTPValidator = (value) => {
  const regexpOTP = /^\d{6}$/gm;

  if (regexpOTP.test(value)) {
    return true;
  }

  return false;
};

export const validatePhoneNumber = (input_str) => {
  var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  return re.test(input_str);
};

export const aadhaarValidator = (value) => {
  const regexpAadhaar =
    /^([0-9]{4}[0-9]{4}[0-9]{4}$)|([0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|([0-9]{4}-[0-9]{4}-[0-9]{4}$)/;

  if (regexpAadhaar.test(value)) {
    return true;
  }

  return false;
};

export const isEmptyOrSpaces = (str) => {
  return str == null || str.match(/^ *$/) !== null;
};

/**
 *
 * @param {*} calendarDates
 * Calculates the date range in days
 */
export const getRangeInDays = (calendarDates) => {
  // DateRangeCalendar component returns an array of selected dates but in case of pre-population we receive an object of dates
  const startDate = Array.isArray(calendarDates) ? calendarDates[0].startDate : calendarDates.start;
  const endDate = Array.isArray(calendarDates) ? calendarDates[0].endDate : calendarDates.end;
  return Math.floor((endDate - startDate) / (1000 * 3600 * 24));
};
export const getDaysBetween = (start, end) => Math.floor((end - start) / (1000 * 3600 * 24));

/**
 *
 * @param {*} username
 * This function takes full name as input and trims,filter and return firstname as first word and lastName as all words except first one
 */

export const toKB = (size) => {
  return `${(size / 1024).toFixed(2)} KB`;
};

export const toMB = (size) => {
  if (size < 0.01 * 1048576) {
    // convert to KB and return
    return toKB(size);
  }

  return `${(size / 1048576).toFixed(2)} MB`;
};

export const getSelectOptions = (options) => {
  if (!options) {
    return [];
  }
  return options.map((option) => {
    return { key: option.id, displayName: option.name.replace('_', ' ') };
  });
};

export const renderForm = (formData, changeHandler, intialState) => {
  return formData.map((formElement) => {
    switch (formElement.fieldType) {
      case FORM_TYPES.SELECT:
        return (
          <div className="reporting-form-item">
            <div className="reporting-form-label">{formElement.fieldLabel}</div>
            <SingleSelect
              options={formElement.fieldOption}
              value={intialState[formElement.fieldName] || ''}
              onChange={(event) => changeHandler(event.target.value, formElement.fieldName)}
            />
          </div>
        );

      case FORM_TYPES.DATE:
        return (
          <div className="reporting-form-item">
            <div className="reporting-form-label">{formElement.fieldLabel}</div>
            <DatePickerComponent
              value={intialState[formElement.fieldName] || ''}
              onChange={(value) => changeHandler(value, formElement.fieldName)}
              maxDate={new Date()}
              minDate={
                formElement.fieldName === 'to_date' && intialState['from_date']
                  ? new Date(intialState['from_date'])
                  : ''
              }
            />
          </div>
        );

      default:
        break;
    }
  });
};
