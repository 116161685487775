const validationMessage = {
  REQUIRED: 'This field is Required',
  MUST_BE_NUMBER: 'must be a valid number',
  SHOULD_BE_GT: 'Should be greater than ',
  MUST_BE_ALPHABETES: 'Name can only contain letters',
  INVALID_EMAIL: 'Please enter valid email ID',
  PASSWORD_MATCH_MSG: 'New password & confirmation does not match',
  MUST_BE_N_DIGITS: 'must be a 10 digit number',
  MUST_BE_N_DIGITS_ALPHANUMERIC: 'must be a 10 digit alphanumeric number',
  PASSWORD_REGEX_MSG: 'Please match the requested format',
  MIN_LENGTH_MSG: 'Please enter valid ',
  INVALID_IFSC: 'Please specify a valid IFSC',
  MUST_BE_ALPHANUMERIC: 'must be an alphanumeric number',
  ACT_MISMATCH: 'Bank Account number & Confirm Bank account number should match'
};
export default validationMessage;
