export const PageNames = {
  dashboard: 'dashboard',
  profile: 'profile',
  selectPartnership: 'selectPartnership',
  getStarted: 'getStarted',
  login: 'login',
  signUp: 'signUp',
  policy: 'policy',
  userProfile: 'userProfile',
  invoices: 'invoices',
  receipts: 'receipts',
  transactions: 'transactions',
  reporting: 'reporting',
  forgotPassword: 'forgotPassword',
  updatePassword: 'updatePassword',
  landing: 'landing',
  vendorLogin: 'vendorLogin',
  vendorForgotPassword: 'vendorForgotPassword',
  vendorUpdatePassword: 'vendorUpdatePassword',
  vendorDashboard: 'vendorDashboard'
};

export const PATHS = {
  [PageNames.landing]: '/',
  [PageNames.dashboard]: '/dashboard',
  [PageNames.profile]: '/dashboard/setting/organization',
  [PageNames.selectPartnership]: '/selectPartnership',
  [PageNames.getStarted]: '/getStarted',
  [PageNames.login]: '/login',
  [PageNames.signUp]: '/sign-up',
  [PageNames.policy]: '/dashboard/policy/list',
  [PageNames.userProfile]: '/dashboard/userProfile',
  [PageNames.transactions]: '/dashboard/transactions',
  [PageNames.receipts]: '/dashboard/transactions/receipts',
  [PageNames.invoices]: '/dashboard/transactions/invoices',
  [PageNames.reporting]: '/dashboard/reporting',
  [PageNames.forgotPassword]: '/forgot-password',
  [PageNames.updatePassword]: '/update-password',
  [PageNames.vendorLogin]: '/vendor/login',
  [PageNames.vendorForgotPassword]: '/vendor/forgot-password',
  [PageNames.vendorUpdatePassword]: '/vendor/update-password',
  [PageNames.vendorDashboard]: '/vendor/insured/users'
};
