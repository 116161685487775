import { setUserType } from './main';
import {
   CS_VID,
   CS_SID,
   UTM_SOURCE,
   UTM_MEDIUM,
   UTM_CAMPAIGN,
   ICID,
   NEW,
   REPEAT,
   AA_PI,
   EMPTY_STRING,
   CS_VISIT_NUMBER_VID,
   CS_VISIT_NUMBER,
   CS_HIT_NUMBER_SID,
   CS_HIT_NUMBER,
} from './constants';
import { fireLogWithSentry } from './sentry';

/**
 * get data from session storage
 * @param {string} key
 * @returns {any}
 */
const getSessionStorage = (key: string) => {
   if (!key) return null;

   try {
      const data = sessionStorage.getItem(key);
      return JSON.parse(data);
   } catch (err) {
      fireLogWithSentry(
         'utils.ts-->getSessionStorage-->error occurred in clickstream sdk',
         err
      );
      return null;
   }
};

/**
 * get url param
 * @param {string} param
 * @returns {string}
 */
const getUrlParam = (param: string) => {
   return new URL(window.location.href).searchParams.get(param);
};

/**
 * set data into session storage
 * @param {string} key
 * @param {any} data
 * @returns {null} optional
 */
const setSessionStorage = (key: string, data: any) => {
   if (!(key && data)) return null;

   try {
      data = JSON.stringify(data);
      sessionStorage.setItem(key, data);
   } catch (err) {
      fireLogWithSentry(
         'utils.ts-->setSessionStorage-->error occurred in clickstream sdk',
         err
      );
      return null;
   }
};

/**
 * get cookies values
 * @param {string} name
 * @returns {any}
 */
const getCookie = (name: string) => {
   const regex = new RegExp(name + '=([^;]+)');
   const value = regex.exec(document.cookie);
   return value != null ? decodeURIComponent(value[1]) : null;
};

/**
 * set values from cookies
 * @param {string} name
 * @param {any} value
 * @param {number} milliSeconds
 */
const setCookie = (name: string, value: any, milliSeconds = 0) => {
   let expires = EMPTY_STRING;
   if (milliSeconds) {
      const date = new Date();
      date.setTime(date.getTime() + milliSeconds);
      expires = ';expires=' + date.toUTCString();
   }
   document.cookie = `${name}=${value}${expires};path=/;`;
};

/**
 * create UUID for user
 * @returns {string}
 */
const create_UUID = () => {
   let dt = new Date().getTime();
   const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
   });
   return uuid;
};

/**
   - userType is calculated on the basis of vid present or not 
      we bind that userType for that particular session

   - sessionId
      refresh logic
         - 30 min of inactivity(no pixel calls)
         - date changes
         - any of the url param(utm_campaign, utm_source, utm_medium) changes
*  @param {string} types
*  @return {string}
*/
const getUUID = (type: string) => {
   let uuid = getCookie(type);
   let milliSeconds;
   let data = uuid;
   let userType = NEW;

   if (type === CS_VID) {
      if (!data) {
         uuid = create_UUID();
         userType = NEW;
      } else {
         userType = REPEAT;
      }
      data = uuid;
      milliSeconds = 365 * 24 * 60 * 60 * 1000;
   } else if (type === CS_SID) {
      try {
         const currentUtmCampaign = getUrlParam(UTM_CAMPAIGN);
         const currentUtmSource = getUrlParam(UTM_SOURCE);
         const currentUtmMedium = getUrlParam(UTM_MEDIUM);
         const icid = getUrlParam(ICID);
         if (!data) {
            uuid = create_UUID();
            const date = new Date().getDate();
            data = JSON.stringify({
               uuid,
               userType,
               date,
               urlParam: {
                  utm_campaign: currentUtmCampaign,
                  utm_source: currentUtmSource,
                  utm_medium: currentUtmMedium,
               },
            });
         } else {
            let createNewIdFlag = false;
            // if sessionId is present, use same userType, uuid
            const tempData = JSON.parse(uuid);
            if (tempData.userType && tempData.userType !== 'undefined') {
               userType = tempData.userType;
            }
            if (tempData.uuid) uuid = tempData.uuid;
            let urlParam = tempData.urlParam;

            /* date changes */
            const previousDate = tempData.date;
            const previousUrlParam = urlParam;

            const nowDate = new Date().getDate();
            if (previousDate && nowDate > previousDate) createNewIdFlag = true;

            /* utm params changes */
            if (previousUrlParam) {
               if (
                  (previousUrlParam.utm_campaign != currentUtmCampaign ||
                     previousUrlParam.utm_source != currentUtmSource ||
                     previousUrlParam.utm_medium != currentUtmMedium) &&
                  !icid
               ) {
                  createNewIdFlag = true;
                  urlParam = {
                     utm_campaign: currentUtmCampaign,
                     utm_source: currentUtmSource,
                     utm_medium: currentUtmMedium,
                  };
               }
            }

            if (createNewIdFlag) uuid = create_UUID();
            /* utm param changes */
            data = JSON.stringify({
               uuid: uuid,
               userType: userType,
               date: nowDate,
               urlParam: urlParam,
            });
         }
      } catch (err) {
         fireLogWithSentry('utils.ts-->getUUID-->error occurred in clickstream sdk', err);
      }
      milliSeconds = 30 * 60 * 1000; //Keeping Session ID only for half hour, VisitorId for 1 years
   }

   setCookie(type, data, milliSeconds);
   setUserType(userType);

   return uuid;
};

/**
 * get session id
 * @param {string} sessionString
 * @returns
 */
const getSessionId = (sessionString: string) => {
   if (sessionString) {
      const arr = sessionString.split('session#');
      if (arr.length > 0) {
         const t = arr[1];
         if (t) {
            return t.split('#')[0];
         }
      }
   }
   return null;
};

/**
 * get page information
 * @param {string} currentPage
 * @returns {object}
 */
const getPageInformation = (currentPage: string) => {
   let previousPage, lastCurrentPage, lastPreviousPage;
   // lastCurrentPage, lastPreviousPage are currentPage and previous page present in last call resp//
   // currentPage, previousPage are currentPage and previous page of present call.

   const pageInformation = getSessionStorage(AA_PI);

   if (pageInformation) {
      lastCurrentPage = pageInformation.cpn;
      lastPreviousPage = pageInformation.ppn;
   } else {
      // for no pageInformation, setting empty
      lastPreviousPage = EMPTY_STRING;
      lastCurrentPage = EMPTY_STRING;
   }

   // if event other than pageView, refresh or tab restore event
   if (!currentPage || (lastCurrentPage && currentPage === lastCurrentPage)) {
      currentPage = lastCurrentPage;
      previousPage = lastPreviousPage;
   } else {
      // new pageView event
      previousPage = lastCurrentPage;
   }

   const currentPageInformation = {
      cpn: currentPage,
      ppn: previousPage,
   };

   setSessionStorage(AA_PI, currentPageInformation);

   return currentPageInformation;
};

/**
 * get browser information
 * @returns {object}
 */
const getBrowserInformation = () => {
   const module = {
      options: [],
      header: [
         navigator.platform,
         navigator.userAgent,
         navigator.appVersion,
         navigator.vendor,
         window.opera,
      ],
      dataOs: [
         { name: 'windows phone', value: 'windows phone', version: 'os' },
         { name: 'windows', value: 'win', version: 'nt' },
         { name: 'iPhone', value: 'iPhone', version: 'os' },
         { name: 'iPad', value: 'iPad', version: 'os' },
         { name: 'kindle', value: 'silk', version: 'silk' },
         { name: 'android', value: 'android', version: 'android' },
         { name: 'playBook', value: 'playBook', version: 'os' },
         { name: 'blackBerry', value: 'blackBerry', version: '/' },
         { name: 'macintosh', value: 'mac', version: 'os x' },
         { name: 'linux', value: 'linux', version: 'rv' },
         { name: 'palm', value: 'palm', version: 'palmOS' },
      ],
      dataBrowser: [
         { name: 'chrome', value: 'chrome', version: 'chrome' },
         { name: 'firefox', value: 'firefox', version: 'firefox' },
         { name: 'safari', value: 'safari', version: 'version' },
         { name: 'internet Explorer', value: 'msie', version: 'msie' },
         { name: 'opera', value: 'opera', version: 'opera' },
         { name: 'blackBerry', value: 'cldc', version: 'cldc' },
         { name: 'mozilla', value: 'mozilla', version: 'mozilla' },
      ],
      init: function () {
         const agent = this.header.join(' '),
            os = this.matchItem(agent, this.dataOs),
            browser = this.matchItem(agent, this.dataBrowser);

         return { os, browser };
      },
      matchItem: function (string, data) {
         let i = 0,
            j = 0,
            regex,
            regexv,
            match,
            matches,
            version;

         for (i = 0; i < data.length; i += 1) {
            regex = new RegExp(data[i].value, 'i');
            match = regex.test(string);
            if (match) {
               regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
               matches = string.match(regexv);
               version = '';
               if (matches) {
                  if (matches[1]) {
                     matches = matches[1];
                  }
               }
               if (matches) {
                  matches = matches.split(/[._]+/);
                  for (j = 0; j < matches.length; j += 1) {
                     if (j === 0) {
                        version += matches[j] + '.';
                     } else {
                        version += matches[j];
                     }
                  }
               } else {
                  version = '0';
               }
               return {
                  name: data[i].name,
                  version: parseFloat(version),
               };
            }
         }
         return { name: 'unknown', version: 0 };
      },
   };

   const e = module.init();

   return {
      osName: e.os.name,
      osVersion: e.os.version,
      browserName: e.browser.name,
      browserVersion: e.browser.version,
   };
};

/**
 * visit number represents the number of times a user has landed on the page
 * it needs to be reset if visitorId (vid) changes
 */
const getVisitNumber = () => {
   const vid = getCookie(CS_VID);
   const visitNumberVID = getCookie(CS_VISIT_NUMBER_VID);

   if (visitNumberVID) {
      if (vid && vid === visitNumberVID) {
         const currentVisitNumber = getCookie(CS_VISIT_NUMBER);
         const updatedVisitNumber = parseInt(currentVisitNumber) + 1;
         setCookie(CS_VISIT_NUMBER, updatedVisitNumber);
         return updatedVisitNumber;
      } else {
         setCookie(CS_VISIT_NUMBER_VID, vid);
         setCookie(CS_VISIT_NUMBER, 1);
         return 1;
      }
   } else {
      setCookie(CS_VISIT_NUMBER_VID, vid);
      setCookie(CS_VISIT_NUMBER, 1);
      return 1;
   }
};

/**
 * hit number depicts the number of times a click event has been captured in one session
 * whether the action is click or not is determined by the "eventAction" sent by frontend
 * it needs to be reset if sessionId (sid) changes
 * @param {string} eventAction
 * @returns {number}
 */
const getHitNumber = (eventAction: string) => {
   const sid = getCookie(CS_SID);
   const hitNumberSID = getCookie(CS_HIT_NUMBER_SID);

   if (hitNumberSID) {
      if (sid && sid === hitNumberSID) {
         const currentHitNumber = getCookie(CS_HIT_NUMBER);
         if (eventAction && eventAction.toLowerCase() === 'click') {
            const updatedHitNumber = parseInt(currentHitNumber) + 1;
            setCookie(CS_HIT_NUMBER, updatedHitNumber);
            return updatedHitNumber;
         } else {
            return currentHitNumber;
         }
      } else {
         setCookie(CS_HIT_NUMBER_SID, sid);
         if (eventAction && eventAction.toLowerCase() === 'click') {
            setCookie(CS_HIT_NUMBER, 1);
            return 1;
         } else {
            setCookie(CS_HIT_NUMBER, 0);
            return 0;
         }
      }
   } else {
      setCookie(CS_HIT_NUMBER_SID, sid);
      if (eventAction && eventAction.toLowerCase() === 'click') {
         setCookie(CS_HIT_NUMBER, 1);
         return 1;
      } else {
         setCookie(CS_HIT_NUMBER, 0);
         return 0;
      }
   }
};

/**
 * determines if this is mobile app or not
 * @returns {boolean}
 */
const isMobileApp = () => {
   return typeof window.MyAirtelAppReact !== 'undefined' ||
      typeof window.webkit !== 'undefined'
      ? 'APP'
      : 'WEB';
};

export {
   getSessionStorage,
   setSessionStorage,
   getCookie,
   setCookie,
   create_UUID,
   getUUID,
   getSessionId,
   getPageInformation,
   getUrlParam,
   getBrowserInformation,
   getVisitNumber,
   getHitNumber,
   isMobileApp,
};
